import React, { useState } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actions as uiActions,
} from '../../../ducks/ui/ui.index';
import {
  actions as maintenanceEventActions
} from '../../../ducks/v2MaintenanceEvent/v2MaintenanceEvent.index';
import { Modal, Button, Form, Tooltip, OverlayTrigger, Row } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import {Formik, Field} from 'formik';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import infoImage from '../../../assets/282532_Information_R_blue.png';
import moment from 'moment-timezone';
/* ========= PACKAGE IMPORTS ========= */
const curDate = new Date();

const schema = yup.object().shape({
  broadcast: yup.string().required().oneOf(['Y', 'N']),
  end_date: yup.date().required(),
  title: yup.string().required(),
  body: yup.string().required(),
  n_type: yup.string().required(),
  mwin_id: yup.number().required(),
  notification_id: yup.number().required(),
});

const mapStateToProps = ({uiState, userState, maintenanceEventState, templateState}) => ({
  pageLoading         :  uiState.pageLoading,
  email               :  userState.user.email, 
  all_data_centers    :  maintenanceEventState.all_data_centers,
  templates           :  templateState.notification_templates,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setPageLoading              :  uiActions.setPageLoading,
    V2SubmitUpdateNotification  :  maintenanceEventActions.V2SubmitUpdateNotification,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};
const PDT = 420; //7 hours, used if daylight savings is active
const PST = 480; //8 hours, used if daylight savings is not active
const MILISECOND_CONVERT = 60000;

const UpdateNotificationModal = ({V2SubmitUpdateNotification, setPageLoading, body, n_type, comm, mwinId, notifId, start_date, end_date, send_time, notif_title, created_by, broadcast, templates, given_subject, mail_queued}) => {
  const [timezone, setTimezone] = useState('Etc/Greenwich');
  if (!send_time){
    send_time = new Date();
  }
  const [startDate, setStartDate] = useState(new Date(new Date(start_date).toLocaleString('en', {timeZone: 'America/Los_Angeles'})));
  const isDST = () => {
    return moment.tz('America/Los_Angeles').isDST();
  }
  const dstActive = isDST();
  const tzIdentifier = dstActive ? "-0700" : "-0800";
  const pacificTimeOffset = dstActive ? PDT : PST;
  const curOffset = startDate.getTimezoneOffset();
  const offsetDif = curOffset - pacificTimeOffset;
  const [sendTime, setSendTime] = useState(new Date(new Date(send_time).toLocaleString('en', {timeZone: 'America/Los_Angeles'})));
  const [formattedStartDate, setFormattedStartDate] = useState(moment(new Date(start_date).toLocaleString('en', {timeZone: timezone})).format('MM/DD/YYYY, hh:mm A'))
  const [formattedEndDate, setFormattedEndDate] = useState(moment(new Date(end_date).toLocaleString('en', {timeZone: timezone})).format('MM/DD/YYYY, hh:mm A'))
  const [endDate, setEndDate] = useState(new Date(new Date(end_date).toLocaleString('en', {timeZone: 'America/Los_Angeles'})));
  const [title, setTitle] = useState(notif_title);
  const [notificationBody, setNotificationBody] = useState(body);
  const [subject, setSubject] = useState(given_subject);
  const [nType, setNType] = useState(n_type);
  let styles = {
    error         :  {
      color       :  'red',
    },
    buttonError   :  {
      color         :  'red',
      marginRight   :  '18px',
      width         : '100vw',
      textAlign     : 'right',

    },
    button        : {
      width       :  '8rem',
    },
    buttonRight   : {
      width       : '8rem',
      margin      : '1rem'
    },
    buttonLeft: {
      width       : '8rem',
      margin      : '1rem auto 1rem 1rem',
      float       : 'left'
    },
    buttonRightDisabled: {
      width       : '8rem',
      marginLeft  : '1rem',
      cursor      : 'default'
    },
    title: {
      textAlign: 'center',
      color: 'red',
    },
    marginRight: {
      marginRight: '1rem',
    },
    confirmationText: {
      marginLeft: '.5rem',
      marginBottom: '.5rem',
      marginTop: '.5rem',
    },
    confirmationHeader: {
      marginBottom: '.5rem',
      marginTop: '.5rem',
      minWidth: '120px'
    },
    infoImg: {
      maxHeight : '1.9rem',
    },
    inlineMiddleVAlign: {
      display : 'inline',
      verticalAlign : 'middle'
    },
    clickableLink  :  {
      cursor    :  'pointer',
      color     :  'blue',
    },
  }
  const [modalIsOpen,setIsOpen] = useState(false);
  const [showConfirmation,setShowConfirmation] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }
  let buttonMessage = '';
  const validate = (values, name, value) => {
    let valid = true;
    if (name == 'start_date' && formatDate(value) == start_date && formatDate(values.end_date) == end_date && values.body == body && values.title === notif_title && values.n_type == n_type){
      buttonMessage = 'Must Modify A Value';
      valid = false;
    }
    if (name == 'end_date' && formatDate(value) == end_date && formatDate(value.start_date) == start_date && values.body == body){
      buttonMessage = 'Must Modify A Value';
      valid = false;
    }

    if (valid){
      buttonMessage = '';
    }
    
  };

  const formatDate = (date) => {
    return moment(date).utc().format('YYYY-MM-DDTHH:mm:ssZZ');
  }

  const formatPstDate = (date) => {
    let tempDate = moment(date).format('YYYY-MM-DDTHH:mm:ssZZ');
    return tempDate.slice(0, -5) + tzIdentifier;
  }

  const onChangeTemplate = (e, values, field, setValues) => {
    const templateVal = e.target.value;
    if (templateVal == 'None'){
      setValues({...values,  selected_template: templateVal});
      return;
    }
    const vals = templates
    let template = null;
    vals.forEach(val => {
      if (val.pkey == templateVal){
        template = val;
      }
    })
    setValues({...values,  selected_template: template.pkey});
    setNotificationBody(template.body);
    field.onChange(e);
  }

  const displayButton = (values, errors) => {
    let displayButton = true;
    buttonMessage = '';
    if (values.n_type == 'Personalized Concur Open'){
      if (values.broadcast === broadcast && values.title === notif_title && values.body === body && values.n_type == n_type && formatDate(values.end_date) == end_date && formatDate(values.start_date) == start_date){
        displayButton = false;
        buttonMessage = 'Must Change A Value';
      }
    }
    else if (values.broadcast == 'Y' && (values.n_type == 'Maintenance Update' || values.n_type == 'Maintenance Cancelled' || values.n_type == 'Maintenance Complete')){
      if (values.broadcast === broadcast && values.title === notif_title && values.body === body && values.n_type == n_type && values.subject == given_subject && formatDate(sendTime) == formatDate(send_time)){
        displayButton = false;
        buttonMessage = 'Must Change A Value';
      }
    }
    else{
      if (values.broadcast === broadcast && values.title === notif_title && values.body === body && values.n_type == n_type && values.subject == given_subject){
        displayButton = false;
        buttonMessage = 'Must Change A Value';
      }
    }
    if (errors.body || !values.body){
      displayButton = false;
      buttonMessage = 'Issue with Notification Body';
    }
    if (errors.title || !values.title){
      displayButton = false;
      buttonMessage = 'Issue with Notification Title';
    }
    if (errors.n_type || !values.n_type){
      displayButton = false;
      buttonMessage = 'Issue with Type';
    }
    return displayButton;
  }

  const handleChange = (e, setValues, values) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    validate(values, name, value);
  };

  const handleNTypeChange = (e, setValues, values) => {
    setNType(e.target.value);
    handleChange(e, setValues, values); 
    if (e.target.value == 'Personalized Concur Open'){
      setValues({...values,  start_date: startDate});
      setValues({...values,  end_date: endDate});
    }
    else{
      delete values.start_date
      delete values.end_date
    }
    if (values.broadcast == 'Y' && (e.target.value == 'Maintenance Update' || e.target.value == 'Maintenance Cancelled' || e.target.value == 'Maintenance Complete')){
      setValues({...values,  send_time: sendTime});
    }
    else{
      delete values.send_time
    }
  }

  const submitFunc = (values) => {
    if (values.n_type != 'Personalized Concur Open'){
      delete values.start_date;
      delete values.end_date;
    }
    else{
      values.start_date = formatPstDate(values.start_date);
      values.end_date = formatPstDate(values.end_date);
    }
    if (values.broadcast == 'Y' && (values.n_type == 'Maintenance Update' || values.n_type == 'Maintenance Cancelled' || values.n_type == 'Maintenance Complete')){
      values.send_time = formatPstDate(values.send_time);
    }
    else{
      delete values.send_time
    }
    V2SubmitUpdateNotification(values);
    setShowConfirmation(false);
    setPageLoading(false);
    closeModal();
  }


  return (
    <div>
      {/* Overlay Tooltip for button */}
      <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Edits this Notification</Tooltip>
        }
      >
        <div className="data-cy-edit-notification-modal-button" style={styles.clickableLink} variant="primary" onClick={openModal}>Edit</div>
      </OverlayTrigger>
      
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-edit-message-modal">
      <Formik   
          enableReinitialize
          validationSchema={schema}
          onSubmit={values => submitFunc(values)}
          validator={() => ({})}
          initialValues={{
            is_test: 'N',
            title: title,
            start_date: startDate,
            end_date: endDate,
            send_time: sendTime,
            body: notificationBody,
            created_by: created_by,
            mwin_id: mwinId,
            notification_id: notifId,
            n_type: nType,
            comm_src: comm ?? '',
            relocalize: 'Y',
            broadcast: broadcast,
            subject: subject,
            created_by: '',
          }}
        >
        {({
          handleSubmit,
          handleBlur,
          setFieldTouched,
          values,
          errors,
          touched,
          setValues,
        }) => 
        (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Notification</Modal.Title>
            </Modal.Header>
            {/* Confirmation Step Title */}
           
            <Modal.Body>
              {/* Main Step Body */}
              <div>
                {nType == 'Personalized Concur Open' && <Form.Group style={{marginBottom: '0px'}}>
                  <div style={{width: '40%', display: 'flex'}}>
                    <div style={{marginRight: '1vw'}}>
                      <Form.Label>Start Date</Form.Label>
                      <DatePicker
                          onChange            = {(date) => {
                            setStartDate(date); 
                            setFormattedStartDate(moment(new Date(date).toLocaleString('en', {timeZone: timezone})).format('MM/DD/YYYY, hh:mm A'));
                            setValues({ ...values, ['start_date']: date }); 
                            validate(values, 'start_date', date);
                            if (date > endDate){
                              setEndDate(date); 
                              setFormattedEndDate(moment(new Date(date).toLocaleString('en', {timeZone: timezone})).format('MM/DD/YYYY, hh:mm A'));
                            }
                          }}
                          selected            = {startDate}
                          showTimeInput       = "true"
                          dateFormat          = "Pp"
                          style               = {{width: '100%'}}  
                          required
                      />
                    </div>
                    
                    <div style={{marginRight: '1vw'}}>
                      <Form.Label>End Date</Form.Label>
                      <DatePicker
                          onChange            = {(date) => {
                            setEndDate(date); 
                            setFormattedEndDate(moment(new Date(date).toLocaleString('en', {timeZone: timezone})).format('MM/DD/YYYY, hh:mm A'));
                            setValues({ ...values, ['end_date']: date }); 
                            validate(values, 'end_date', date);
                          }}
                          selected            = {endDate}
                          showTimeInput       = "true"
                          dateFormat          = "Pp"
                          style               = {{width: '100%'}}  
                          minDate             = {values.start_date}
                          required
                          
                      />
                    </div>
                   
                  </div>
                </Form.Group>}
                {nType == 'Personalized Concur Open' && <Row style={{marginBottom: '1rem'}}>
                  <div style={{width: '40%', display: 'flex', fontSize: '14px'}}>{formattedStartDate}</div>
                  <div style={{width: '40%', display: 'flex', fontSize: '14px', marginLeft: '1vw'}}>{formattedEndDate}</div>
                </Row>}
               
                {nType == 'Personalized Concur Open' &&<Form.Group style={{display: 'block', width: '40%'}}>   
                  <Form.Label>Preview Timezone</Form.Label>
                  <Form.Control 
                    as="select" 
                    name="timezone" 
                    value={timezone}  
                    onChange={(e) => {
                      //these dates come in as pst, but with the timezone denotion of your local time like -0500
                      //this converts that offset to pst, so it can then be converted into the desired timezone accurately
                      const adjustedEndDate = new Date(endDate.getTime() - offsetDif * MILISECOND_CONVERT)
                      const adjustedStartDate = new Date(startDate.getTime() - offsetDif * MILISECOND_CONVERT)
                      setFormattedEndDate(moment.tz(adjustedEndDate, e.target.value).format('MM/DD/YYYY, hh:mm A'))
                      setFormattedStartDate(moment.tz(adjustedStartDate, e.target.value).format('MM/DD/YYYY, hh:mm A'))
                      setTimezone(e.target.value);
                    }}>
                   <option value="Europe/Paris">{dstActive ? "CEST" : "CET"}</option>
                    <option value="Etc/Greenwich">GMT</option>
                    <option value="US/Central">{dstActive ? "CDT" : "CST"}</option>
                    <option value="America/Los_Angeles">{dstActive ? "PDT" : "PST"}</option>
                  </Form.Control>
                </Form.Group>}
                
                <Form.Group style={{display: 'flex'}}>      
                  <div style={{marginRight: '3vw'}}>
                    <Form.Label>Type</Form.Label>     
                    <Form.Control 
                      as="select" 
                      name="n_type" 
                      value={nType}  
                      onChange={(e) => { handleNTypeChange(e, setValues, values);}}>
                      <option value="Maintenance Announcement">Maintenance Announcement</option>
                      <option value="Maintenance Update">Maintenance Update</option>
                      <option value="Maintenance Complete">Maintenance Complete</option>
                      <option value="Maintenance Cancelled">Maintenance Cancelled</option>
                      <option value="Personalized Concur Open">Personalized Concur Open</option>
                    </Form.Control>
                    </div>
                </Form.Group>  
                {nType != 'Personalized Concur Open' && <Form.Group>
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type="textarea"
                    as="textarea"
                    className="data-cy-create-message-modal-title"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    onBlur={handleBlur}
                    name="subject"></Form.Control>
                    {errors.subject && touched.subject ? (
                      <div style={styles.error}>{errors.subject}</div>
                    ) : null}
                </Form.Group>}    
                <Form.Group>
                  <Form.Label>Notification Title</Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    className="data-cy-create-notification-modal-title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      handleChange(e, setValues, values);
                    }}
                    onBlur={handleBlur}
                    name="title"></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Insert Template Text</Form.Label>
                  <OverlayTrigger
                    key='right'
                    placement='right'
                    overlay=
                    {
                      <Tooltip id={`tooltip-right`}>
                        Inserts text into the Notification Body field. WARNING will delete Notification Body contents.
                      </Tooltip>
                    }>
                    <img src = {infoImage} style = {{ ...styles.infoImg, ...styles.inlineMiddleVAlign }} />
                  </OverlayTrigger>
                  <Field>
                    {({ field }) => (
                      <Form.Control as="select" name="template_text" value={values.selected_template} onBlur={handleBlur} onChange={e => onChangeTemplate(e, values, field, setValues)}>
                        <option key='None' value='None'></option>
                        { templates.map((type, i) => {
                          if (type['notification_type'] == values.n_type)return ( <option key={'template-' + i} value={type.pkey} id={type.body}>{type.title}</option>)
                          })}
                        
                      </Form.Control>
                    )}
                  </Field>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Notification Body</Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    className="data-cy-create-notification-modal-body"
                    value={notificationBody}
                    rows={5}
                    onChange={(e) => {
                      setNotificationBody(e.target.value);
                      handleChange(e, setValues, values);
                    }}
                    onBlur={handleBlur}
                    name="body"></Form.Control>
                </Form.Group>
                {nType != 'Personalized Concur Open' && <Form.Group>
                    
                    <Form.Label>Send Email?</Form.Label>
                    <OverlayTrigger
                    key='right'
                    placement='right'
                    overlay=
                    {
                      <Tooltip id={`tooltip-right`}>
                        If enabled, users will have the ability to send an email associated with this notification.
                      </Tooltip>
                    }>
                    <img src = {infoImage} style = {{ ...styles.infoImg, ...styles.inlineMiddleVAlign }} />
                  </OverlayTrigger>
                    <Field>
                      {({ field }) => (
                        <Form.Group as={Row} className="data-cy-create-message-modal-broadcast" name="broadcast" value={values.broadcast} onBlur={handleBlur} onChange={e => handleChange(e, setValues, values)} >
                           <Form.Check
                            type="radio"
                            name="broadcast"
                            label='Yes'
                            value="Y"
                            className="data-cy-create-message-modal-broadcast-Y"
                            id="emailYes"
                            style={styles.marginRight}
                            checked={values.broadcast === 'Y'}
                            onChange={e => handleChange(e, setValues, values)}
                          />
                          <Form.Check
                            type="radio"
                            label="No"
                            name="broadcast"
                            value="N"
                            className="data-cy-create-message-modal-broadcast-N"
                            id="emailNo"
                            checked={values.broadcast === 'N'}
                            onChange={e => handleChange(e, setValues, values)}
                          />
                        </Form.Group>

                      )}
                    </Field>
                    {errors.broadcast && touched.broadcast ? (
                        <div style={styles.error}>{errors.broadcast}</div>
                      ) : null}
                  </Form.Group>}
                {values.broadcast === 'Y' && <Form.Group style={{display: 'block', width: '40%'}}>   
                  <Form.Label>Send Time (Pacific Time)</Form.Label>
                  <DatePicker
                      onChange            = {(date) => {
                        setSendTime(date); 
                      }}
                      selected            = {sendTime}
                      showTimeInput       = "true"
                      dateFormat          = "Pp"
                      style               = {{width: '100%'}}  
                      required
                  />
                </Form.Group>}
              </div>
            </Modal.Body>
            
            <Modal.Footer>
              <div style={{display: 'contents'}}>
                <div style={styles.buttonError}>{buttonMessage}</div>
                <Button variant="secondary" onClick={closeModal} className="data-cy-create-notification-modal-close" style={styles.buttonLeft}>Cancel</Button>
                <Button style={styles.buttonRight} disabled={!displayButton(values, errors)} className="data-cy-create-notification-modal-ok" type="submit">Submit</Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
        </Formik>

      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(UpdateNotificationModal);