import { takeEvery, put, select, call } from 'redux-saga/effects';
import { types as auditTypes, actions as auditActions, } from './audit.index';
import { getAudits, getAuditOptions } from './audit.api';
import { actions as errorActions } from '../error/error.index';
import moment from 'moment';

// worker sagas
export function* fetchAudits(action) {
  const { payload } = action
  try {
    yield put(auditActions.setAuditLoading(true));
    const response = yield call(getAudits, 
      payload.application_name.toString(), 
      payload.user_email.toString(), 
      payload.action_name.toString(), 
      moment(payload.date_range_start).unix(), 
      moment(payload.date_range_end).unix(), 
      payload.order_by, 
      payload.start_at, 
      payload.response_size);
    let {audit_log, more_results}  =  response.data.payload;
    yield put(auditActions.setAudits(audit_log));
    yield put(auditActions.setMoreResults(more_results));
    yield put(auditActions.setAuditLoading(false));
  } catch (error) {
    const entireState = yield select();
    yield put(auditActions.setAuditLoading(false));
    yield put(errorActions.raiseError({ error, entireState }));
  }
}

export function* fetchAuditOptions(action) {
  try {
    yield put(auditActions.setAuditOptionsLoading(true));
    const response = yield call(getAuditOptions);
    let payload  =  response.data.payload;
    yield put(auditActions.setAuditOptions(payload));
    yield put(auditActions.setAuditOptionsLoading(false));
  } catch (error) {
    const entireState = yield select();
    yield put(auditActions.setAuditOptionsLoading(false));
    yield put(errorActions.raiseError({ error, entireState }));
  }
}


// watcher saga
export default function userSagas() {
  return [
    takeEvery(auditTypes.GET_AUDITS, fetchAudits),
    takeEvery(auditTypes.GET_AUDIT_OPTIONS, fetchAuditOptions),
  ];
}
