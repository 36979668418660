import { func } from 'prop-types';

/* ===== STATE_DEFINITION ===== */
export const initialState = {
  audits   : [],
  auditOptions: {},
  auditLoading: true,
  auditOptionsLoading: true,
  moreResults: true,
};


/* ===== PROP_TYPES ===== */
export const administrationProptypes = {
  getAudits : func,
  getAuditOptions: func,
  setAudits : func,
  setAuditOptions: func,
  setAuditLoading: func,
  setAuditOptionsLoading: func,
  setMoreResults: func,
}


/* ===== TYPES ===== */
export const types = {
  GET_AUDITS          : '[audit] GET_AUDITS',
  GET_AUDIT_OPTIONS   : '[audit] GET_AUDIT_OPTIONS',
  SET_AUDITS          : '[audit] SET_AUDITS',
  SET_AUDIT_OPTIONS   : '[audit] SET_AUDIT_OPTIONS',
  SET_AUDIT_LOADING   : '[audit] SET_AUDIT_LOADING',
  SET_AUDIT_OPTIONS_LOADING   : '[audit] SET_AUDIT_OPTIONS_LOADING',
  SET_MORE_RESULTS    : '[audit] SET_MORE_RESULTS',
};


/* ===== ACTION_CREATORS ===== */
export const actions = {
  getAudits         : (application_name, user_email, action_name, date_range_start, date_range_end, order_by, start_at, response_size) => ({ type: types.GET_AUDITS, payload: {application_name, user_email, action_name, date_range_start, date_range_end, order_by, start_at, response_size} }),
  getAuditOptions   : () => ({ type: types.GET_AUDIT_OPTIONS}),
  setAudits         : (audits) => ({ type: types.SET_AUDITS, payload: audits }),
  setAuditOptions   : (options) => ({ type: types.SET_AUDIT_OPTIONS, payload: options }),
  setAuditLoading   : (loading) => ({ type: types.SET_AUDIT_LOADING, payload: loading}),
  setAuditOptionsLoading   : (loading) => ({ type: types.SET_AUDIT_OPTIONS_LOADING, payload: loading}),
  setMoreResults    : (moreResults) => ({ type: types.SET_MORE_RESULTS, payload: moreResults}),
}

/* ===== SELECTORS ===== */
export const selectors =  {

};


/* ===== REDUCER ===== */
export default function reducer(state=initialState, action) {
  switch (action.type) {
    case types.SET_AUDITS: {
      return {...state, audits :  action.payload, };
    }
    case types.SET_AUDIT_OPTIONS: {
      return {...state, auditOptions  :  action.payload, };
    }
    case types.SET_AUDIT_LOADING: {
      return {...state, auditLoading :  action.payload, };
    }
    case types.SET_AUDIT_OPTIONS_LOADING: {
      return {...state, auditOptionsLoading :  action.payload, };
    }
    case types.SET_MORE_RESULTS: {
      return {...state, moreResults :  action.payload, };
    }
    default :  { return state; }
  }
}
