import React, {useEffect}      from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Row } from 'react-bootstrap';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import { actions as securityIncidentActions } from '../../ducks/securityIncidents/securityIncident.index';
import Loading            from '../../components/loading';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import SortTable from '../Tables/SortTable';
import {
  sortOpi,
} from '../../utils/formatFunctions'
import PromoteModal from './Modals/PromoteModal';
import DeleteModalTemplate from './Modals/DeleteModalTemplate';
import UpdateStagedEmailModal from './Modals/UpdateStagedEmailModal';

const mapStateToProps = ({securityIncidentState}, props) => {
  let importId;
  let secId;
  let commId;
  importId = props.match.params['import_id'];
  secId = props.match.params['sec_id'];
  commId = props.match.params['comm_id'];
  return {
    importId: importId,
    secId          : secId,
    commId         : commId,
    stagingEmailsLoading : securityIncidentState.stagingEmailsLoading,
    stagingEmails: securityIncidentState.stagingEmails,
  }
};

const mapDispatchToProps = (dispatch) => {
const combinedActionCreators = {
  requestStagingEmails: securityIncidentActions.requestStagingEmails,
  requestPromoteStagingEmails: securityIncidentActions.requestPromoteStagingEmails,
  requestDeleteStagingEmails: securityIncidentActions.requestDeleteStagingEmails,


};
return bindActionCreators(combinedActionCreators, dispatch);
};
const styles = {
  'topContainer': {
    'margin'   : 'auto',
    'maxWidth' : 'max-content',
    'minWidth' : '73vw',
  },
  'pageTitle': {
    'textAlign'    :  'center',
    'fontSize'     :  '2rem',
    'marginLeft'   :  'auto',
    'marginRight'  :  'auto',
  },
};

const requiredAction = 'view_staging_emails';

const StagedEmails = ({requestStagingEmails, importId, secId, commId, stagingEmailsLoading, stagingEmails, requestPromoteStagingEmails, requestDeleteStagingEmails}) => {
  const { topContainer, pageTitle, headers } = styles;
  useEffect(() => {
    if (importId){
      requestStagingEmails(importId);
    }
  }, [importId]);

  const columns = React.useMemo(
    () => [
        {
          Header: <b style={styles.test} data-cy='id-header'>ID</b>,
          headerStyle: headers,
          id: 'pkey',
          size: '122px',
          Cell          : ({value}) => <div>{value}</div>,
          accessor      : "pkey",
          sortMethod    : (a, b) => sortOpi(a, b),
        },
        {
          Header      : <b data-cy="internal-header">Comm Id</b>,
          headerStyle : headers,
          id          : 'sec_comm_id',
          size       : '50px',
          accessor    : data => <div style={{width: '50px'}}>{(data.sec_comm_id)}</div>,
        },
        {
          Header: <b data-cy='service-header'>Company Id</b>,
          headerStyle: headers,
          id: 'company_id',
          accessor    : data => <div style={{width: '100px'}}>{data.company_id}</div>,
          size: '260px',
        },
        {
          Header      : <b data-cy="internal-header">Subject</b>,
          headerStyle : headers,
          id          : 'subject',
          size       : '100px',
          accessor    : data => <div>{data.subject}</div>
        },
        {
          Header      : <b data-cy="internal-header">Review Email</b>,
          headerStyle : headers,
          id          : 'was_promoted',
          size       : '100px',
          accessor    : data => <PromoteModal canPromote={!data.was_promoted} submitFunction={requestPromoteStagingEmails} submitData={{"ids": [data.pkey]}} id={data.pkey} email={data} companyId={data.company_id}  body={data.body} subject={data.subject}/>
        },
        {
          Header: <b data-cy='data-center-header'>Edit Import</b>,
          headerStyle: headers,
          id: 'edit',
          width: 50,
          accessor: (data) => <UpdateStagedEmailModal data={data} />
        },
        {
          Header: <b data-cy='data-center-header'>Delete</b>,
          headerStyle: headers,
          id: 'delete',
          width: 50,
          accessor: (data) => <DeleteModalTemplate 
          deleteFunction={requestDeleteStagingEmails}
          delete_id={data.pkey} 
          data={data}
          keys={["pkey", "sec_comm_id", "company_id", "subject", "was_promoted"]}
          displayKeys={["Staging Email ID", "Comm ID", "Company Id", "Subject", "Was Promoted"]} 
          type="Staging Emails"
          html_modal={false} />
        },
      ]
    )
    const sort = {
      id: "created_date",
      desc: true
    }
    const widthDict = {
      'id': 50,
      'sec_comm_id': 50,
      'company_id': 50,
      'subject': 300,
      'was_promoted': 50,
      'view': 50,
      'edit': 50,
      'delete': 50,
    }

return (
  <AuthorizationWrapper requiredAction={requiredAction}>
    <div style={topContainer}>
      {
        stagingEmailsLoading
        ? <div>
            <div style={pageTitle}>Loading...</div>
            <Loading height="75vh" />
          </div>
        : <div>
            <Row>
              <div style={pageTitle}>Mass Import</div>
            </Row>
            <Row>
            {secId && commId && <Breadcrumb style={{marginTop: 'auto'}}>
                <Breadcrumb.Item href="/security_incidents">Security Incidents</Breadcrumb.Item>
                <Breadcrumb.Item href={"/comms/" + secId}>Comms</Breadcrumb.Item>
                <Breadcrumb.Item href={"/emails/" + commId}>Emails</Breadcrumb.Item>
                <Breadcrumb.Item href={`/mass_import/${secId}/${commId}`}>Mass Import</Breadcrumb.Item>
                <Breadcrumb.Item active>Staging Emails</Breadcrumb.Item>
              </Breadcrumb>}
              {!secId && !commId && <Breadcrumb style={{marginTop: 'auto'}}>
                <Breadcrumb.Item href="/security_incidents">Security Incidents</Breadcrumb.Item>
                <Breadcrumb.Item href="/mass_import">Mass Import</Breadcrumb.Item>
                <Breadcrumb.Item active>Staging Emails</Breadcrumb.Item>
              </Breadcrumb>}
            </Row>
            <SortTable data={stagingEmails} columns={columns} defaultSort={sort} widthDict={widthDict} />
          </div>
      }
    </div>


  </AuthorizationWrapper>
)
}

export default connect(mapStateToProps, mapDispatchToProps)(StagedEmails);