import React, {useEffect}      from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Button, Row } from 'react-bootstrap';
import { Link }     from 'react-router-dom';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import { actions as securityIncidentActions } from '../../ducks/securityIncidents/securityIncident.index';
import Loading            from '../../components/loading';
import SortTable from '../Tables/SortTable';
import CreateImportModal from './Modals/CreateImportModal';
import { MdExpandMore } from "react-icons/md";
import DeleteModalTemplate from './Modals/DeleteModalTemplate';
import ShowCsvData from './Modals/ShowCsvData';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import './breadcrumbs.css'
import {
  dateTimeToUTCString,
  sortOpi,
  sortDate,
  newSortDate,
} from '../../utils/formatFunctions'
const mapStateToProps = ({securityIncidentState}, props) => {
  let secId;
  let commId;
  secId = props.match.params['sec_id'];
  commId = props.match.params['comm_id'];
  return {
    secId          : secId,
    commId         : commId,
    importsLoading : securityIncidentState.importsLoading,
    imports: securityIncidentState.imports,
  }
};

const mapDispatchToProps = (dispatch) => {
const combinedActionCreators = {
  requestImports : securityIncidentActions.requestImports,
  requestComms        : securityIncidentActions.requestComms,
  requestSecurityIncidents : securityIncidentActions.requestSecurityIncidents,
  requestCommTypes: securityIncidentActions.requestCommTypes,
  requestDeleteImports : securityIncidentActions.requestDeleteImports,

};
return bindActionCreators(combinedActionCreators, dispatch);
};
const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
    'minWidth' :  '73vw',
  },
  'pageTitle': {
    'textAlign'    :  'center',
    'marginLeft'   :  'auto',
    'marginRight'  :  'auto',
    'fontSize'     :  '2rem',
  },
};

const requiredAction = 'view_mass_import';

const MassImport = ({requestImports, requestSecurityIncidents, requestCommTypes, importsLoading, requestDeleteImports, imports, secId="", commId=""}) => {
  const { topContainer, pageTitle, headers } = styles;
  useEffect(() => {
    requestImports();
    requestCommTypes();
    requestSecurityIncidents();
  }, []);

  const columns = React.useMemo(
    () => [
        {
          Header: <b style={styles.test} data-cy='id-header'>Import ID</b>,
          headerStyle: headers,
          id: 'pkey',
          size: '50px',
          Cell          : ({value}) => <div>{value}</div>,
          accessor      : "pkey",
          sortMethod    : (a, b) => sortOpi(a, b),
        },
        {
          Header: <b style={styles.test} data-cy='id-header'>Security Incident ID</b>,
          headerStyle: headers,
          id: 'sec_id',
          size: '50px',
          Cell          : ({value}) => <div style={{width: '50px'}}>{value}</div>,
          accessor      : "sec_id",
        },
        {
          Header      : <b data-cy="internal-header">Security Comm Id</b>,
          headerStyle : headers,
          id          : 'sec_comm_id',
          size       : '50px',
          accessor    : data => <div style={{width: '50px'}}>{(data.sec_comm_id)}</div>,
        },
        {
          Header: <b data-cy='service-header'>Title</b>,
          headerStyle: headers,
          id: 'title',
          accessor    : data => <div style={{width: '100px'}}>{data.title}</div>,
          size: '260px',
        },
       
        {
          Header      : <b data-cy="internal-header">Data</b>,
          headerStyle : headers,
          id          : 'data',
          size       : '100px',
          accessor    : data => <ShowCsvData data={data.data}/>
        },
        {
          Header: <b data-cy='service-header'>FileName</b>,
          headerStyle: headers,
          id: 'filename',
          accessor    : data => <div style={{width: '180px'}}>{data.filename}</div>,
          size: '260px',
        },
        {
          Header: <b data-cy="created-date-header">Import Date</b> ,
          headerStyle: headers,
          id: 'created_date',
          accessor: 'created_date',
          size: '230px',
          Cell      : ({value}) => (<div style={{width: '230px'}}>{dateTimeToUTCString(value)}</div>),
          sortType: newSortDate,
        },
        {
          Header      : <b data-cy="internal-header">Imported By</b>,
          headerStyle : headers,
          id          : 'created_by',
          size       : '200px',
          accessor    : data => <div style={{width: '180px'}}>{data.created_by}</div>,
        },
        {
          Header: <b data-cy='data-center-header'>Review Data</b>,
          headerStyle: headers,
          id: 'pkey3',
          width: 50,
          accessor: (data) => <Link to={(secId && commId) ?`/staged_emails/${secId}/${commId}/${data.pkey}` : `/staged_emails/${data.pkey}`} style={{marginLeft: '10px'}} ><MdExpandMore style={{fontSize: "35px"}}/></Link> 
        },
        {
          Header: <b data-cy='data-center-header'>Delete</b>,
          headerStyle: headers,
          id: 'pkey4',
          width: 50,
          accessor: (data) => <DeleteModalTemplate 
          deleteFunction={requestDeleteImports}
          delete_id={data.pkey} 
          data={data}
          keys={["pkey", "sec_comm_id", "title", "file_name", "created_date", "created_by"]}
          displayKeys={["Import ID", "Comm ID", "Title", "File Name", "Created Date", "Created By"]} 
          type="Imports"
          html_modal={false} />
        },
      ]
    )
  const sort = {
    id: "created_date",
    desc: true
  }
  const widthDict = {
    'pkey': 50,
    'sec_id': 50,
    'sec_comm_id': 122,
    'comm_type': 260,
    'description': 300,
    'created_date': 230,
    'status': 230
  }

return (
  <AuthorizationWrapper requiredAction={requiredAction}>
    <div style={topContainer}>
      {
        importsLoading
        ? <div>
            <div style={pageTitle}>Loading...</div>
            <Loading height="75vh" />
          </div>
        : <div>
            <Row>
              <div style={pageTitle}>Mail Merge Management</div>
            </Row>
            <Row>
              {secId && commId && <Breadcrumb style={{marginTop: 'auto'}}>
                <Breadcrumb.Item href="/security_incidents">Security Incidents</Breadcrumb.Item>
                <Breadcrumb.Item href={"/comms/" + secId}>Comms</Breadcrumb.Item>
                <Breadcrumb.Item href={"/emails/" + commId}>Emails</Breadcrumb.Item>
                <Breadcrumb.Item active>Mass Import</Breadcrumb.Item>
              </Breadcrumb>}
              {!secId && !commId && <Breadcrumb style={{marginTop: 'auto'}}>
                <Breadcrumb.Item href="/security_incidents">Security Incidents</Breadcrumb.Item>
                <Breadcrumb.Item active>Mass Import</Breadcrumb.Item>
              </Breadcrumb>}
              <CreateImportModal sec_id={secId} comm_id={commId}/>
            </Row>
            <SortTable data={imports} columns={columns} defaultSort={sort} widthDict={widthDict}/>
          </div>
      }
    </div>
  </AuthorizationWrapper>
)
}

export default connect(mapStateToProps, mapDispatchToProps)(MassImport);