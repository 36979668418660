import React,  {useState, Fragment}       from 'react';
import { useTable, useSortBy } from 'react-table'
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const mapStateToProps = () => ({
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const styles = {
  'evenRow':{
    'backgroundColor': '#007bff2e'
  },
  'tableContainer': {
    'fontSize'        :  '.94rem',
    'boxSizing'       :  'border-box',
    'borderRadius'    :  '4px',
    'backgroundColor' :  'rgb(250, 250, 250)',
    'boxShadow'       :  'rgba(0, 0, 0, 0.2) 0px 0px 10px -2px',
  },
  'noData': {
    'textAlign': 'center',
  }
};

function RenderTable({ columns, values, defaultSort, widthDict, sortCallback, sortDirection }) {
  const { t } = useTranslation(["translation"]);

  const data = React.useMemo(() => 
  values
  , [values]);

  const sorting = React.useMemo(
    () => [
      defaultSort
    ],
    []
  );
  const generateSortingIndicator = (column) => {
    if (sortCallback && sortDirection && column.isSorted && column.id == 'created_date'){
      if (column.isSortedDesc && sortDirection == 'ASC'){
        sortCallback('DESC');
      }
      else if (!column.isSortedDesc && sortDirection == 'DESC'){
        sortCallback('ASC');
      }
    }
    return column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : '';
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: sorting,
      }
    },
    useSortBy,
  )
  return (
    <Fragment>
      <Table bordered {...getTableProps()} className="cy-history-table">  
        <thead>
          {headerGroups.map(headerGroup => (
            <tr className='rt-thead' {...headerGroup.getHeaderGroupProps()} >
              {headerGroup.headers.map((column, i) => {
                const {render, getHeaderProps} = column
                return (
                  <th style={{ width: column.size || widthDict[column.id] }} className={generateSortingIndicator(column) + " " + column.id + "-header"} key={'header' + i} {...getHeaderProps(column.getSortByToggleProps())}>{render("Header")}</th>
                )
              })}
            </tr>
          ))}
        </thead>
        {rows.length > 0 && <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {        
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                <tr style={index % 2 == 0 ? styles.evenRow : {}}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })}
        </tbody>}
        {rows.length == 0 &&  <>
          <tbody>
            {
              [...Array(7)].map((e, i) => {
                if(i == 3){
                  return(<tr key={'row-' + i} style={styles.noData} className='rt-noData'><td colSpan={columns.length}>No Data</td></tr>)
                } 
                else{
                  return (<tr key={'row-' + i}>{columns.map((e, j) => (<td key={'col-' + j}/>))}</tr>)
                }
              })
            }
          </tbody>
        </>}
      </Table>
     
    </Fragment>
  );
}

const SortTable = ({data, columns, defaultSort, widthDict, sortCallback=null, sortDirection=null}) => {
  const { headers, tableContainer } = styles;
  const { t } = useTranslation(["translation"]);
  const [rowData, setRowData] = useState(data);
  if (document.getElementsByClassName("-center")[0]){
    document.getElementsByClassName("-center")[0].style.display = 'none'
  }

  return (
    <div style={tableContainer}>
      <RenderTable columns={columns} values={rowData} defaultSort={defaultSort} widthDict={widthDict} sortCallback={sortCallback} sortDirection={sortDirection}/>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(SortTable);
