import React, { useState } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actions as securityIncidentActions
} from '../../../ducks/securityIncidents/securityIncident.index';
import { MdOutlineMode } from "react-icons/md";
import { Modal, Button, Form, Tooltip, OverlayTrigger, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
/* ========= PACKAGE IMPORTS ========= */
const mapStateToProps = ({securityIncidentState}) => ({
  emailContents       : securityIncidentState.emailContents,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setEmailContents                         : securityIncidentActions.setEmailContents,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const UpdateComposeEmailModal = ({emailContents, setEmailContents, index}) => {
  const { t } = useTranslation(["translation"]);
  let styles = {
    buttonRight   : {
      width       : '8rem',
      margin      : '1rem'
    },
    buttonLeft: {
      width       : '8rem',
      margin      : '1rem auto 1rem 1rem',
      float       : 'left'
    }
  }
  const [modalIsOpen,setIsOpen] = useState(false);
  const [input, setInput] = useState('');
  const [type, setType] = useState('');
  const [tableWidth, setTableWidth] = useState(1);
  const [tableHeight, setTableHeight] = useState(1);
  const [tableHeaders, setTableHeaders] = useState(['', '']);
  const [tableData, setTableData] = useState([["", ""], ["", ""]]);
  const [errorMsg, setErrorMsg] = useState('');

  const openModal = () => {
    let item = emailContents[index];
    let text = item.props.children;
    if (item.props.className === 'entity-table'){
      setType('table');
      let headers = text[0].props.children.props.children;
      setTableWidth(headers.length);
      let headerArray = headers.map(header => header.props.children);
      setTableHeaders(headerArray);
      let data = text[1].props.children;
      setTableHeight(data.length);
      let dataArray = data.map(row => row.props.children.map(cell => cell.props.children));
      setTableData(dataArray);
    }
    else if (!text){
      text = item.props.dangerouslySetInnerHTML.__html;
      
      if (text.includes('<table')){
        setType('table');
        let headers = text.matchAll(/<th [^>]+>(.*?)<\/th>/gm);
        let headerArray = [];
        for (const header of headers) {
          headerArray.push(header[1]);
        }
        const realWidth = headerArray.length;
        setTableWidth(realWidth);
        setTableHeaders(headerArray);
        let rows = text.matchAll(/<td [^>]+>(.*?)<\/td>/gm);
        let rowArray = [];
        for (const row of rows) {
          rowArray.push(row[1]);
        }
        const height = rowArray.length / realWidth;
        setTableHeight(height);
        var dataArray = [];
        for (let i = 0; i < height; i++) {
          let tempRow = [];
          for (let j = 0; j < realWidth; j++) {
            tempRow.push(rowArray[i * realWidth + j]);
          }
          dataArray.push(tempRow);
        }

        setTableData(dataArray);
      }
      else{
        const tagText = text.match(/(?<=>)(.*?)(?=<.*\/.+.?>)/gs);
        setInput(tagText);
        const regex = /(?:class|className)=(?:["']\W+\s*(?:\w+)\()?["']([^'"]+)['"]/g
        const tagClass = regex.exec(text)[1];
        setType(tagClass);
      }

    }
    else {
      setType(item.props.className);
      setInput(text);
    }
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const resetTable = () => {
    setTableHeaders([]);
    setTableWidth(2);
    setTableHeight(2);
    setTableData([["", ""], ["", ""]]);
  }

  const onSubmit = () => {
    if (type === 'table'){
      let newEmailContents = [...emailContents];
      let emptyCol = false;
      newEmailContents[index] = (<table key={"entity-table-" + newEmailContents.length} className="entity-table"><thead><tr>
      {tableHeaders.map((header, index) => {
        if (header === ''){
          emptyCol = true;
        }
        return (<th key={index}>{header}</th>)
      })}</tr></thead><tbody>
      {tableData.map((row, i) => {
        return (
        <tr>
        {row.map((data, j) => {
          if (data === ''){
            emptyCol = true;
          }
          return (<td key={i + '-' + j}>{data}</td>)
        })}
        </tr>
      )})}
      </tbody>
      </table>)
      if (emptyCol){
        setErrorMsg('Table cannot have empty columns or rows');
        return;
      }
      setEmailContents(newEmailContents);
      resetTable();
      closeModal();
    }
    else{
    emailContents[index] = <p className={type} key={type + '-' + index}>{input}</p>;
    setEmailContents(emailContents);
    setInput('');
    closeModal();
    }
  }

  const changeTableDimensions = (height, width) => {
    if (height > tableHeight) {
      for (let i = tableHeight; i < height; i++) {
        var row = [];
        for (let j = 0; j < tableWidth; j++) {
          row.push('');
        }
        tableData.push(row);
      }
    }
    else if (height < tableHeight) {
      tableData.splice(height, tableHeight - height);
    }
    else{
      let newTableData = [];
      for (let i = 0; i < tableHeight; i++) {
        var row = [];
        for (let j = 0; j < tableWidth; j++) {
          row.push(tableData[i][j] ?? '');
        }
        newTableData.push(row);
      }
      setTableData(newTableData);
      return;
    }
    setTableData(tableData);
  }

  const isInt = (value) => {
    return !isNaN(value) && 
           parseInt(Number(value)) == value && 
           !isNaN(parseInt(value, 10));
  }

  const changeTableWidth = (newWidth) => {
    setErrorMsg('');
    if (isInt(newWidth) && newWidth > 0) {
      setTableWidth(parseInt(newWidth));
      changeTableDimensions(tableHeight, parseInt(newWidth));
    }
    else{
      setErrorMsg('Value must be a positive integer');
    }
  }

  const changeTableHeight = (newHeight) => {
    setErrorMsg('');
    if (isInt(newHeight) && newHeight > 0) {
      setTableHeight(parseInt(newHeight));
      changeTableDimensions(parseInt(newHeight), tableWidth);
    }
    else{
      setErrorMsg('Value must be a positive integer');
    }
  }

  const changeTableData = (newData, i, j) => {
    setErrorMsg('');
    const newTableData = [...tableData];
    newTableData[i][j] = newData;
    setTableData(newTableData);
  }

  const changeTableHeaders = (newData, i) => {
    setErrorMsg('');
    const newTableHeaders = [...tableHeaders];
    console.log(newData, 'newData')
    newTableHeaders[i] = newData;
    setTableHeaders(newTableHeaders);
  }


  return (
    <div>
      <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Edits this email line</Tooltip>
        }
      >
        <MdOutlineMode onClick={openModal}/>
      </OverlayTrigger>
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-create-message-modal">
        <Modal.Header>
          <Modal.Title>Edit {type}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
          {type != 'table' && <Form.Control as="textarea" value={input} onChange={e => setInput(e.target.value)}></Form.Control>}
          {type == 'table' && 
          <div>

            <Row style={{marginBottom: '3rem'}}>
              <Col>
                <Form.Label>Table Width</Form.Label>
                <Form.Control as="input" value={tableWidth} onChange={e => changeTableWidth(e.target.value)}></Form.Control>
              </Col>
              <Col>
                <Form.Label>Table Height</Form.Label>
                <Form.Control as="input" value={tableHeight} onChange={e => changeTableHeight(e.target.value)}></Form.Control>
              </Col>
            </Row>
            <Row>Headers</Row>
            <Row>
              {Array.from(Array(tableWidth)).map((_, i) => {
                return (
                  <Col key={'col' + i} style={{marginBottom: '5px'}}>
                    <Form.Control key={'header-' + i} type="text" placeholder="header" value={tableHeaders[i] || ['']} onChange={e => changeTableHeaders(e.target.value, i)}></Form.Control>
                  </Col>
                )
              })}
            </Row>
            <Row>Data</Row>
            {Array.from(Array(tableHeight)).map((_, i) => {
              return(
                <Row key={'row' + i}>
                  {Array.from(Array(tableWidth)).map((_, j) => {
                    return (
                      <Col key={'rowcol' + i + '-' + j} style={{marginBottom: '5px'}}>
                        <Form.Control key={"updateTabledata-" + i + "-" + j} placeholder="data" value={tableData[i][j]} onChange={e => changeTableData(e.target.value, i, j)}></Form.Control>
                      </Col>
                    )
                  })
                }
                </Row>
              )})
            }
          </div>}
          </Form>
          <Row style={{color: 'red'}}>{errorMsg}</Row>
        </Modal.Body>
        <Modal.Footer>
          <div style={{display: 'contents'}}>
            <Button variant="secondary" onClick={closeModal} className="data-cy-update-compose-email-modal-close" style={styles.buttonLeft}>Cancel</Button>
            <Button style={styles.buttonRight} className="data-cy-update-compose-email-modal-ok" onClick={e => onSubmit(type)}>Submit</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(UpdateComposeEmailModal);