import { takeEvery, put, call, select } from "redux-saga/effects";
import i18next from 'i18next';
import {selectors} from '../user/user.index';

import {
  getAllActiveIncidents,
  getAllHistoricIncidents,
  getIncidentDetails,
  getAffectedCompanies,
  getIncidents,
  getEmailFields,
  createMessage,
  updateMessage,
  deleteMessage,
  updateMessageBody,
  getIncidentMessages,
  getP2AlertTypes,
  getBannerState,
  updateBanner,
  getIncidentByJiraId,
 } from './incident.api';
import { types as incidentTypes, actions as incidentActions } from './incident.index';
import { actions as uiActions }     from '../ui/ui.index';
import { actions as errorActions }  from '../error/error.index';
import { actions as toastActions }  from '../toast/toast.index';

const INCIDENT_RESPONSE_SIZE = 500;
const INCIDENT_RESPONSE_START_SIZE = 50;
const AFFECTED_COMPANY_RESPONSE_SIZE = 30000;
const AFFECTED_COMPANY_START_SIZE = 100;

const getLanguage = () => {
  //language loaded or is not english
  if (i18next.language && !i18next.language.toLocaleLowerCase().includes('en')){
    return i18next.language;
  }
  //returns default english for if language is not loaded or if en is present in the locale
  return 'en';
}

export function* fetchAllActiveIncidents() {
  try {
    //First run, language will always be en and i18next wont be loaded yet.
    let language = getLanguage();
    let response =  yield call(getAllActiveIncidents, language, 0, INCIDENT_RESPONSE_SIZE);
    let payload  =  response.data.payload;

    yield put(incidentActions.setAllActiveIncidents(payload));
    yield put(uiActions.setRefreshTime());
    yield put(incidentActions.setAllIncidentsLoading(false));
  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to check for active incidents: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
    yield put(incidentActions.setIncidentError(true));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchAllHistoricIncidents() {
  try {
    //First run, language will always be en and i18next wont be loaded yet.
    yield put(incidentActions.setAllHistoricIncidents([]));
    let language = getLanguage();
    let response =  yield call(getAllHistoricIncidents, language, 0, INCIDENT_RESPONSE_START_SIZE);
    let payload  =  response.data.payload;
    let more_results = response.data.more_results;

    yield put(incidentActions.setAllHistoricIncidents(payload));
    yield put(uiActions.setPageLoading(false));
    response =  yield call(getAllHistoricIncidents, language, INCIDENT_RESPONSE_START_SIZE, INCIDENT_RESPONSE_SIZE - INCIDENT_RESPONSE_START_SIZE);
    payload  =  response.data.payload;
    more_results = response.data.more_results;
    yield put(incidentActions.setAllHistoricIncidents(payload));
    let counter = 1;
    while (more_results){
      response =  yield call(getAllHistoricIncidents, language, INCIDENT_RESPONSE_SIZE * counter, INCIDENT_RESPONSE_SIZE);
      payload  =  response.data.payload;
      more_results = response.data.more_results;
      yield put(incidentActions.setAllHistoricIncidents(payload));
      counter += 1;
    }
    
    yield put(uiActions.setRefreshTime());
    yield put(uiActions.setPageLoading(false));

  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to check for historic incidents: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
    yield put(uiActions.setPageLoading(true));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchIncidentByJiraId(action) {
  try {
    //First run, language will always be en and i18next wont be loaded yet.
    yield put(incidentActions.setSpecificIncidentLoading(true));
    let language = getLanguage();
    let response =  yield call(getIncidentByJiraId, action.payload, language);
    let payload  =  response.data.payload;
    yield put(incidentActions.setSelectedIncident(payload));
    yield put(uiActions.setRefreshTime());
    yield put(uiActions.setPageLoading(false));
    yield put(incidentActions.setSpecificIncidentLoading(false));

  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to check for incident by jira id: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
    yield put(uiActions.setPageLoading(true));
    yield put(incidentActions.setSpecificIncidentLoading(false));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchIncidentDetails(action) {
  try{
    // call endpoint
    let response =  yield call(getIncidentDetails, action.payload);
    let payload  =  response.data;
    // update state
    yield put(incidentActions.setIncidentDetails(payload));
    yield put(uiActions.setRefreshTime());
  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to get details about that incident: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchAffectedCompanies(action) {
  try{
    // quick initial load
    let response =  yield call(getAffectedCompanies, action.payload, 0, AFFECTED_COMPANY_START_SIZE);
    let payload  =  response.data;
    let more_results = payload['more_results'];
    delete payload['more_results'];
    yield put(incidentActions.setIncidentDetails(payload));
    // total up to AFFECTED_COMPANY_RESPONSE_SIZE to make algo below simpler
    response =  yield call(getAffectedCompanies, action.payload, AFFECTED_COMPANY_START_SIZE, AFFECTED_COMPANY_RESPONSE_SIZE - AFFECTED_COMPANY_START_SIZE);
    payload  =  response.data;
    more_results = payload['more_results'];
    delete payload['more_results'];
    yield put(incidentActions.setIncidentDetails(payload));
    // loop to get the rest of the results
    let counter = 1;
    while (more_results){
      response =  yield call(getAffectedCompanies, action.payload, AFFECTED_COMPANY_RESPONSE_SIZE * counter, AFFECTED_COMPANY_RESPONSE_SIZE);
      payload  =  response.data;
      more_results = response.data['more_results']
      delete payload['more_results'];
      yield put(incidentActions.setIncidentDetails(payload));
      counter += 1;
    }
    yield put(uiActions.setRefreshTime());
  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to get affected companies for this incident: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchIncidents(action) {
  try {
    // call endpoint
    yield put(incidentActions.setAllIncidentsLoading(true));
    let response =  yield call(getIncidents, action.payload);
    let payload  =  response.data;

    // update state
    if (action.payload.start_at == 0){
      yield put(incidentActions.setIncidents(action.payload.incidentType, payload.incidents))
    }
    else{
      yield put(incidentActions.appendIncidents(action.payload.incidentType, payload.incidents))
    }
    yield put(uiActions.setPageLoading(false));
    yield put(incidentActions.setAllIncidentsLoading(false));
  }
  catch (error) {
    const message      =  `There was an issue fetching the incidents. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }

}

export function* fetchEmailFields() {
  try {
    // call endpoint
    let response =  yield call(getEmailFields);
    let payload  =  response.data;
    // update state
    yield put(incidentActions.setEmailFields(payload.services));
    yield put(uiActions.setPageLoading(false));
  }
  catch (error) {
    const message      =  `There was an issue fetching the email fields. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }

}

export function* submitCreateMessage(action) {
  try{
    // extract relevant variables
    yield put(incidentActions.setSpecificIncidentLoading(true));
    const payload =  action.payload;
    // submit it to changeEntity api endpoint
    const userEmail = yield select(selectors.getUserEmail);
    payload.message.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Create Message"
    }
    const response = yield call(createMessage, payload.message);
    yield put(incidentActions.requestIncidentByJiraId(payload.message.jira_id));
    yield put(incidentActions.setSpecificIncidentLoading(false));
    yield put(toastActions.createToast('Message Created Successfully!', {type: 'success'}))
  }
  catch (error) {
    const message      =  `There was an issue creating the message. Please try again. ${error}`;
    if (message.includes('504')){
      yield put(incidentActions.requestIncidentByJiraId(action.payload.message.jira_id));
      yield put(incidentActions.setSpecificIncidentLoading(false));
      yield put(toastActions.createToast('Message Created Successfully!', {type: 'success'}))
    }
    else{
      const toastOptions =  {type: 'error', autoClose: false};
      yield put(toastActions.createToast(message, toastOptions));
    }
  }
}

export function* submitUpdateMessage(action) {
  try{
    // extract relevant variables
    yield put(incidentActions.setSpecificIncidentLoading(true));
    const payload =  action.payload;
    const userEmail = yield select(selectors.getUserEmail);
    payload.message.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Update Message"
    }
    const otherResponse = yield call(updateMessageBody, payload.message);
    yield put(incidentActions.requestIncidentByJiraId(payload.message.jira_id));
    yield put(incidentActions.setSpecificIncidentLoading(false));
    yield put(toastActions.createToast('Message Updated Successfully!', {type: 'success'}))
  }
  catch (error) {
    const message      =  `There was an issue updating the message. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* submitDeleteMessage(action) {
  try{
    // extract relevant variables
    yield put(incidentActions.setSpecificIncidentLoading(true));
    const payload =  action.payload;
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Delete Message"
    }
    const response = yield call(deleteMessage, payload);
    yield put(incidentActions.requestIncidentByJiraId(payload.jira_id));
    yield put(incidentActions.setSpecificIncidentLoading(false));
    yield put(toastActions.createToast('Message deleted Successfully!', {type: 'success'}))
  }
  catch (error) {
    const message      =  `There was an issue deleting the message. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* fetchIncidentMessages(action) {
  try {
    // call endpoint
    yield put(uiActions.setPageLoading(true));
    let response =  yield call(getIncidentMessages, action.payload.id);
    let payload  =  response.data;
    
    // update state
    yield put(incidentActions.setIncidentMessages(payload.messages, action.payload.priorityType));
    yield put(uiActions.setPageLoading(false));
    yield put(incidentActions.setMessagesLoading(false));
  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to get messages for this incident: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchBannerState() {
  try {
    // call endpoint
    yield put(uiActions.setPageLoading(true));
    let response =  yield call(getBannerState);
    let payload  =  response.data;

    // update state
    yield put(incidentActions.setBannerText(payload.payload.data.text.en));
    yield put(incidentActions.setBannerDisplay(payload.payload.data.display));
    yield put(uiActions.setPageLoading(false));
  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to get banner state: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitUpdateBanner(action) {
  try {
    // call endpoint
    console.log('submitUpdateBanner');
    yield put(uiActions.setPageLoading(true));
    const userEmail = yield select(selectors.getUserEmail);
    let bannerPayload = {
      'display'       : action.payload.showBanner, 
      'jira_id'       : 'Manual',
      'source'        : action.payload.email, 
      'source_service': 'aviary' ,
      'audit_log_data': {
        'application_name'  :"Aviary",
        'user_email'        :userEmail,
        'action_name'       :"Update Banner"
      }
    }
    let response =  yield call(updateBanner, bannerPayload);
    let payload  =  response.data;
    // update state
    yield put(incidentActions.requestBannerState());
    yield put(uiActions.setPageLoading(false));
    yield put(toastActions.createToast('Banner Updated Successfully!', {type: 'success'}))
  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to update banner state: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchP2AlertTypes() {
  try {
    // call endpoint
    let response =  yield call(getP2AlertTypes);
    let payload  =  response.data.payload;
    let p2types = [];
    let p1types = [];
    
    if (payload['P2']){
      let alert_types = payload['P2'].alert_types;
      alert_types.forEach(alert_type => {
        p2types.push(alert_type.name);
      })
    }
    if (payload['P1']){
      let alert_types = payload['P1'].alert_types;
      alert_types.forEach(alert_type => {
        p1types.push(alert_type.name);
      })
    }
    
    // update state
    yield put(incidentActions.setP2AlertTypes(p2types));
    yield put(incidentActions.setP1AlertTypes(p1types));
    yield put(uiActions.setPageLoading(false));
  }
  catch (error) {
    const message      =  `There was an issue fetching the P2 alert types. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }

}

// watcher saga
export default function incidentSagas() {
  return [
    takeEvery(incidentTypes.REQUEST_ALL_ACTIVE_INCIDENTS, fetchAllActiveIncidents),
    takeEvery(incidentTypes.REQUEST_ALL_HISTORIC_INCIDENTS, fetchAllHistoricIncidents),
    takeEvery(incidentTypes.REQUEST_INCIDENT_DETAILS, fetchIncidentDetails),
    takeEvery(incidentTypes.REQUEST_AFFECTED_COMPANIES, fetchAffectedCompanies),
    takeEvery(incidentTypes.REQUEST_INCIDENTS, fetchIncidents),
    takeEvery(incidentTypes.REQUEST_EMAIL_FIELDS, fetchEmailFields),
    takeEvery(incidentTypes.SUBMIT_CREATE_MESSAGE, submitCreateMessage),
    takeEvery(incidentTypes.SUBMIT_UPDATE_MESSAGE, submitUpdateMessage),
    takeEvery(incidentTypes.SUBMIT_DELETE_MESSAGE, submitDeleteMessage),
    takeEvery(incidentTypes.REQUEST_INCIDENT_MESSAGES, fetchIncidentMessages),
    takeEvery(incidentTypes.REQUEST_P2_ALERT_TYPES, fetchP2AlertTypes),
    takeEvery(incidentTypes.REQUEST_BANNER_STATE, fetchBannerState),
    takeEvery(incidentTypes.SUBMIT_UPDATE_BANNER, submitUpdateBanner),
    takeEvery(incidentTypes.REQUEST_INCIDENT_BY_ID, fetchIncidentByJiraId),
  ];
}
