import http from "../../services/httpService";

let apiEndpoint = '/v2/maintenance_event';

export const getV2MaintenanceEvents = async () => {
  const result = await http.get(`${apiEndpoint}`);
  return result;
};
export const getAllV2DataCenters = async () => {
  const result = await http.get('data_centers');
  return result;
};

export const getV2ActiveNotifications = async () => {
  const result = await http.get('/v2/active_notifications');
  return result;
};

export const createV2MaintenanceEvent = async (payload) => await http.post(`${apiEndpoint}`, payload);
export const updateV2MaintenanceEvent = async (payload) => await http.put(`${apiEndpoint}`, payload);
export const deleteV2MaintenanceEvent = async (payload) => await http.delete(`v2/delete_maintenance_event`, {data: payload});

export const createV2Notification = async (payload) => await http.post(`/notifications`, payload);
export const updateV2Notification = async (payload) => await http.put(`/notifications`, payload);
export const deleteV2Notification = async (payload) => await http.delete(`/notifications/`, {data: payload});