import http from "../../services/httpService";

let apiEndpoint = '/sec';

export const getCommTypes = async () => await http.get(`${apiEndpoint}/comm_types/`);

export const getSecurityIncidents = async ()          => await http.get(`${apiEndpoint}/incidents/`);
export const createSecurityIncident = async (payload) => await http.post(`${apiEndpoint}/incidents/`, payload);
export const updateSecurityIncident = async (payload) => await http.put(`${apiEndpoint}/incidents/`, payload);
export const deleteSecurityIncident = async (payload)      => await http.delete(`${apiEndpoint}/incidents/delete/`, {data: payload});

export const getCommsId   = async (sec_id)  => await http.get(`${apiEndpoint}/comms/?sec_id=${sec_id}`);
export const getComms   = async ()  => await http.get(`${apiEndpoint}/comms/`);
export const createComm = async (payload) => await http.post(`${apiEndpoint}/comms/`, payload);
export const updateComm = async (payload) => await http.put(`${apiEndpoint}/comms/`, payload);
export const deleteComm = async (payload)      => await http.delete(`${apiEndpoint}/comms/delete/`, {data: payload});

export const getEmails          = async (sec_comm_id)     => await http.get(`${apiEndpoint}/emails/?sec_comm_id=${sec_comm_id}`);
export const getSpecificEmails  = async (comm_content_id) => await http.get(`${apiEndpoint}/emails/?comm_content_id=${comm_content_id}`);
export const createEmails       = async (payload)         => await http.post(`${apiEndpoint}/emails/`, payload);
export const updateEmails       = async (payload)         => await http.put(`${apiEndpoint}/emails/`, payload);
export const deleteEmails       = async (payload) => await http.delete(`${apiEndpoint}/emails/delete/`, {data: payload});

export const getSecurityUsers    = async (start_at, response_size, email, company_id) => await http.get(`${apiEndpoint}/user/?start_at=${start_at}&response_size=${response_size}&email=${email}&company_id=${company_id}`); 
export const getSecurityUsersImpact = async (company_ids) => await http.get(`${apiEndpoint}/user/impact/?company_ids=${company_ids}`);

export const createSecurityUsers = async (payload) => await http.post(`${apiEndpoint}/user/`, payload);
export const updateSecurityUsers = async (payload) => await http.put(`${apiEndpoint}/user/`, payload);
export const deleteSecurityUsers = async (payload) => await http.delete(`${apiEndpoint}/user/delete/`, {data: payload});
 
export const getImports    = async ()        => await http.get(`${apiEndpoint}/imports/`);
export const createImports = async (payload) => await http.post(`${apiEndpoint}/imports/`, payload);
export const updateImports = async (payload) => await http.put(`${apiEndpoint}/imports/`, payload);
export const deleteImports = async (payload)      => await http.delete(`${apiEndpoint}/imports/delete/`, {data: payload});

export const getStagingEmails    = async ()        => await http.get(`${apiEndpoint}/staging/`);
export const createStagingEmails = async (payload) => await http.post(`${apiEndpoint}/staging/`, payload);
export const updateStagingEmails = async (payload) => await http.put(`${apiEndpoint}/staging/`, payload);
export const promoteStagingEmails = async (payload)      => await http.put(`${apiEndpoint}/staging/promote/`, payload);
export const deleteStagingEmails = async (payload)      => await http.delete(`${apiEndpoint}/staging/delete/`, {data: payload});

export const sendEmails = async (payload) => await http.post(`${apiEndpoint}/send_emails/`, payload);
