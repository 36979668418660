import React,  {useState}       from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table'
import { Table, Card, InputGroup, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const styles = {
  'evenRow':{
    'backgroundColor': '#007bff2e'
  },
  'tableContainer': {
    'fontSize'        :  '.94rem',
    'boxSizing'       :  'border-box',
    'borderRadius'    :  '4px',
    'backgroundColor' :  'rgb(250, 250, 250)',
    'boxShadow'       :  'rgba(0, 0, 0, 0.2) 0px 0px 10px -2px',
  },
  'noData': {
    'textAlign': 'center',
  }
};

function RenderTable({ columns, values }) {
  const { t } = useTranslation(["translation"]);
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const data = React.useMemo(() => 
  values
  , [values]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      // defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter // useGlobalFilter!
  )

   // Define a default UI for filtering
   function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    }){
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
      document.getElementById("search-bar").focus()
    }, 800)

    return (
      <span>
        <InputGroup>
          <InputGroup.Prepend style={{margin: 'auto .5rem'}}>Filter{' '}</InputGroup.Prepend>
          <FormControl id="search-bar" value={value || ""}
            onChange={e => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={`${count} records...`}
            style={{
              fontSize: '1.1rem',
              borderTop: '0',
              borderBottom: '0'
            }} />
        </InputGroup>
      </span>
    )
  }

  return (
    <>
    <Card>
       <GlobalFilter
         preGlobalFilteredRows={preGlobalFilteredRows}
         globalFilter={state.globalFilter}
         setGlobalFilter={setGlobalFilter}
       />
     </Card>
     <Table bordered {...getTableProps()}>
       <thead>
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => {
               const {render, getHeaderProps} = column
               return (
                 <th {...getHeaderProps()}>{render("Header")}</th>
               )
             })}
           </tr>
         ))}
       </thead>
       <tbody {...getTableBodyProps()}>
         {rows.map((row, i) => {
           prepareRow(row);
           return (

             <tr {...row.getRowProps()} >
               {row.cells.map(cell => {
                 return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
               })}
             </tr>
           );
         })}
       </tbody>
     </Table>
   </>
  );
}

const SearchTable = ({data, columns}) => {
  const { tableContainer } = styles;
  const { t } = useTranslation(["translation"]);
  const [rowData, setRowData] = useState(data);
  if (document.getElementsByClassName("-center")[0]){
    document.getElementsByClassName("-center")[0].style.display = 'none'
  }

 

  return (
    <div style={tableContainer}>
      <RenderTable columns={columns} values={rowData} />
    </div>
  );
}

export default SearchTable;
