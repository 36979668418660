import React,  {useEffect, useState}       from 'react';
import { useTable, } from 'react-table'
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const styles = {
  'evenRow':{
    'backgroundColor': '#007bff2e'
  },
  'tableContainer': {
    'fontSize'        :  '.94rem',
    'boxSizing'       :  'border-box',
    'borderRadius'    :  '4px',
    'backgroundColor' :  'rgb(250, 250, 250)',
    'boxShadow'       :  'rgba(0, 0, 0, 0.2) 0px 0px 10px -2px',
  },
  'noData': {
    'textAlign': 'center',
  },
  'pageTitle': {
    'width'        :  '73vw',
    'textAlign'    :  'center',
    'fontSize'     :  '2rem',
  },
};

function RenderTable({ columns, values }) {
  const { t } = useTranslation(["translation"]);

  const data = React.useMemo(() => 
  values
  , [values]);

  const isNumeric = (str) => {
    if (typeof str != "string") return false 
    return !isNaN(str) && 
           !isNaN(parseFloat(str)) 
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      // defaultColumn, // Be sure to pass the defaultColumn option
    },
  )

  return (
    <>
     <Table bordered {...getTableProps()}>
       <thead>
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => {
               const {render, getHeaderProps} = column
               return (
                 <th {...getHeaderProps()}>{render("Header")}</th>
               )
             })}
           </tr>
         ))}
       </thead>
       <tbody {...getTableBodyProps()}>
         {rows.map((row, i) => {
           prepareRow(row);
           return (

             <tr {...row.getRowProps()} >
               {row.cells.map(cell => {
                 return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
               })}
             </tr>
           );
         })}
       </tbody>
     </Table>
   </>
  );
}

const ManageSecurityUsersTable = ({data, columns}) => {
  const { tableContainer } = styles;
  const { t } = useTranslation(["translation"]);
  const [rowData, setRowData] = useState(data);
  if (document.getElementsByClassName("-center")[0]){
    document.getElementsByClassName("-center")[0].style.display = 'none'
  }

  useEffect(() => {
    document.getElementById("search-bar").focus()
  }
  , [data]);

  return (
    <div style={tableContainer}>
      <RenderTable columns={columns} values={rowData}/>
    </div>
  );
}

export default ManageSecurityUsersTable;
