/* ========= PACKAGE IMPORTS ========= */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { actions } from '../../ducks/changeHistory/changeHistory.index';
import { actions as userActions, selectors as userSelectors } from '../../ducks/user/user.index';
import "./ChangeHistory.css";
import _ from 'lodash';
import moment from 'moment';

/* ========= COMPONENT IMPORTS ========= */
import { Col, Row, Card } from 'react-bootstrap';
import FilterTile from './FilterTile/FilterTile';
import ChangeHistoryTileHeader from './ChangeHistoryTile/ChangeHistoryTileHeader';
import ChangeHistoryTileBody from './ChangeHistoryTile/ChangeHistoryTileBody';
import ChangeHistoryTileFooter from './ChangeHistoryTile/ChangeHistoryTileFooter';

const URLPARAMKEYS = ['change_type_options', 'keywords', 'page_number', 'search_type', 'selected_cis', 'selected_data_centers', 
'selected_services', 'sort_direction', 'absolute_selected', 'relative_time_unit_options', 'date_range_start', 'date_range_end']
const SEARCH_TYPE_OPTIONS = ['any', 'opi', 'company_id', 'description', 'summary', 'owned_by', 'catalog_type']

const RELATIVE_TIME_UNITS = ['minutes', 'hours', 'days', 'weeks', 'months']
const styles = {
  currentPageButton: {
    width : "100px"
  },
  fullWidth: {
    width : '100%'
  },
  blur: {
    filter        : 'blur(2px)',
    pointerEvents : 'none'
  },
  noPadding: {
    padding   : '0',
    overflowY : 'hidden'
  }
};

/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({changeHistoryState, userState}) => ({
  loading_data_centers        : changeHistoryState.loading_data_centers,
  entity_results_data_centers : changeHistoryState.entity_results_data_centers,
  selected_data_centers       : changeHistoryState.selected_data_centers,
  loading_services            : changeHistoryState.loading_services,
  entity_results_services     : changeHistoryState.entity_results_services,
  selected_services           : changeHistoryState.selected_services,
  loading_cis                 : changeHistoryState.loading_cis,
  entity_results_cis          : changeHistoryState.entity_results_cis,
  selected_cis                : changeHistoryState.selected_cis,
  change_type                 : changeHistoryState.change_type,
  change_type_options         : changeHistoryState.change_type_options,
  search_type_options         : changeHistoryState.search_type_options,
  relative_time_unit_options  : changeHistoryState.relative_time_unit_options,
  relative_time_number        : changeHistoryState.relative_time_number,
  relative_time_exceeded      : changeHistoryState.relative_time_exceeded,
  absolute_selected           : changeHistoryState.absolute_selected,
  record_display_style        : changeHistoryState.record_display_style,
  date_range_start            : changeHistoryState.date_range_start,
  date_range_end              : changeHistoryState.date_range_end,
  sort_by                     : changeHistoryState.sort_by,
  sort_direction              : changeHistoryState.sort_direction,
  page_number                 : changeHistoryState.page_number,
  results_per_page            : changeHistoryState.results_per_page,
  keywords                    : changeHistoryState.keywords,
  pageLoading                 : changeHistoryState.pageLoading,
  apiResultsLoaded            : changeHistoryState.apiResultsLoaded,
  searchChangeResponse        : changeHistoryState.searchChangeResponse,
  searchChangeLoading         : changeHistoryState.searchChangeLoading,
  maxMonthsDateRange          : changeHistoryState.maxMonthsDateRange,
  error                       : changeHistoryState.error,
  errorMessage                : changeHistoryState.errorMessage,
  changeHistoryFilters        : userState.changeHistorySettings
});

const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    submitSearchChanges        :  actions.submitSearchChanges,
    submitEntitySearch         :  actions.submitEntitySearch,
    setChangeTypeOptions       :  actions.setChangeTypeOptions,
    setSearchTypeOptions       :  actions.setSearchTypeOptions,
    setRelativeTimeUnitOptions :  actions.setRelativeTimeUnitOptions,
    setStateField              :  actions.setStateField,
    resetFilters               :  actions.resetFilters,
    putChangeHistorySettings   :  userActions.putChangeHistorySettings
  }

  return bindActionCreators(combinedActionCreators, dispatch);
};

class ChangeHistory extends Component {
  static propTypes = {};
  
  componentDidMount = () => {
    let savedUrlParams = new URLSearchParams();
    this.parseUrlParams();
    try {
      // Go through the user's change history filters provided by the userState and apply them to the view
      Object.keys(this.props.changeHistoryFilters).forEach((key) => {
        //Since saved state was updated with all url params, add the saved state params to url to make sure it's up to date
        if (URLPARAMKEYS.includes(key)){
          //change type is stored goofy
          if (key == 'change_type_options'){
            if (this.props.changeHistoryFilters[key][0]['selected']){
              savedUrlParams.set(key, 'manual');
            }
            else{
              savedUrlParams.set(key, 'auto');
            }
          }
          else if (key == 'data_range_start' || key == 'date_range_end'){
            savedUrlParams.set(key, moment(this.props.changeHistoryFilters[key]).unix());
          }
          else if (key == 'relative_time_unit_options'){
            for (let i = 0; i < RELATIVE_TIME_UNITS.length; i++){
              if (this.props.changeHistoryFilters[key][i]['selected']){
                savedUrlParams.set(key, this.props.changeHistoryFilters[key][i]['systemKey']);
              }
            }
          }
          else{
            savedUrlParams.set(key, this.props.changeHistoryFilters[key].toString());
          }
        }
        switch(key) {
          case 'date_range_start':
          case 'date_range_end':
            // These time fields are a special exception because they must be readable by the DatePicker component.
            // They're stored in the DB as timestamps but must be converted to date objects.
            this.props.setStateField(key, moment(this.props.changeHistoryFilters[key]).toDate());
            break;
          default:
            this.props.setStateField(key, this.props.changeHistoryFilters[key]);
            break;
        }
      });
      window.history.pushState({}, window.location.href, window.location.pathname + "?" + savedUrlParams)
      // Now that all the filters are loaded from user's state, run the search
      this.props.submitSearchChanges();
    } catch(err) {
      console.log(err)
    }
  }

  componentDidUpdate() {
    if (this.props.apiResultsLoaded && this.props.record_display_style != 'card'){
      this.adjustHeaders();
    }
    //update url params for any changes made in the saved state
    let savedUrlParams = new URLSearchParams(window.location.search);
    Object.keys(this.props.changeHistoryFilters).forEach((key) => {
      if (URLPARAMKEYS.includes(key)){
        //change type is stored goofy
        if (key == 'change_type_options'){
          if (this.props.changeHistoryFilters[key][0]['selected']){
            savedUrlParams.set(key, 'manual');
          }
          else{
            savedUrlParams.set(key, 'auto');
          }
        }
        else if (key == 'date_range_start' || key == 'date_range_end'){
          savedUrlParams.set(key, moment(this.props.changeHistoryFilters[key]).unix());
        }
        else if (key == 'relative_time_unit_options'){
          for (let i = 0; i < RELATIVE_TIME_UNITS.length; i++){     
            if (this.props.changeHistoryFilters[key][i]['selected']){
              savedUrlParams.set(key, this.props.changeHistoryFilters[key][i]['systemKey']);
              break;
            }
          }
        }
        else{
          savedUrlParams.set(key, this.props.changeHistoryFilters[key].toString());
        }
      }
    })
    window.history.pushState({}, window.location.href, window.location.pathname + "?" + savedUrlParams)
  }

  componentWillUnmount = () => {}

  parseUrlParams = () => {
    //get url params, then apply them to the saved state as the url params are the source of truth
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.forEach((value, key) => {
      if (value){
        switch(key) {
          case 'change_type_options':
            if (value == 'manual'){
              this.props.changeHistoryFilters[key] = [{systemKey: 'manual', displayVal: 'Manual', selected: true}, {systemKey: 'auto', displayVal: 'Auto', selected: false}];
              this.props.setStateField(key, [{systemKey: 'manual', displayVal: 'Manual', selected: true}, {systemKey: 'auto', displayVal: 'Auto', selected: false}])
            }
            else{
              this.props.changeHistoryFilters[key] = [{systemKey: 'manual', displayVal: 'Manual', selected: false}, {systemKey: 'auto', displayVal: 'Auto', selected: true}];
              this.props.setStateField(key, [{systemKey: 'manual', displayVal: 'Manual', selected: false}, {systemKey: 'auto', displayVal: 'Auto', selected: true}])
            }
            break;
          case 'search_type':
            let typeIndex = SEARCH_TYPE_OPTIONS.indexOf(value.toLowerCase());
            for (let i = 0; i < SEARCH_TYPE_OPTIONS.length; i++){
            // for (let i = 0; i < this.props.changeHistoryFilters["search_type_options"].length; i++){  // TODO: we may consider changing the list used for determining loop length
              if (i == typeIndex){
                this.props.changeHistoryFilters["search_type_options"][i]['selected'] = true
              }
              else{
                this.props.changeHistoryFilters["search_type_options"][i]['selected'] = false
              }
            }
            break;
          case 'relative_time_unit_options':
            let unitIndex = RELATIVE_TIME_UNITS.indexOf(value.toLowerCase());
            for (let i = 0; i < RELATIVE_TIME_UNITS.length; i++){
              if (i == unitIndex){
                this.props.changeHistoryFilters["relative_time_unit_options"][i]['selected'] = true
              }
              else{
                this.props.changeHistoryFilters["relative_time_unit_options"][i]['selected'] = false
              }
            }
            break;
          case 'selected_cis':
            this.props.changeHistoryFilters[key] = value.split(',');
            this.props.setStateField(key, value.split(','));
            break;
          case 'selected_data_centers':
            this.props.changeHistoryFilters[key] = value.split(',');
            this.props.setStateField(key, value.split(','));
            break;
          case 'selected_services':
            this.props.changeHistoryFilters[key] = value.split(',');
            this.props.setStateField(key, value.split(','));
            break;
          case 'absolute_selected':
            if (value.toLocaleLowerCase() == 'true' || value.toLowerCase() == 'false'){
              this.props.changeHistoryFilters[key] = (value == 'true');
              this.props.setStateField(key, (value == 'true'));
            }
            break;
          case 'date_range_start':
            this.props.changeHistoryFilters[key] = moment.unix(value).format("MMM D, YYYY h:mm A");
            this.props.setStateField(key, moment.unix(value).format("MMM D, YYYY h:mm A"));
            break;
          case 'date_range_end':
            this.props.changeHistoryFilters[key] = moment.unix(value).format("MMM D, YYYY h:mm A");
            this.props.setStateField(key, moment.unix(value).format("MMM D, YYYY h:mm A"));
            break;
          default:
            this.props.changeHistoryFilters[key] = value;
            this.props.setStateField(key, value);
            break;
        }
      }
    })
  }

  //this function fixes the mismatched header/col widths so the actually align correctly
  adjustHeaders = () => {
    const tableBody = document.querySelector('.rt-tbody');
    if (tableBody) {
        const tBodyWidth = tableBody.clientWidth;
        const tBodyStyle = window.getComputedStyle(tableBody);
        if (tBodyStyle && tBodyStyle.width) {
            const tBodyOuterWidth = parseInt(tBodyStyle.width.slice(0, -2), 0);
            const headerBody = document.querySelectorAll('.rt-tbody')
            if (headerBody) {
              const margin = tBodyWidth < tBodyOuterWidth ? tBodyOuterWidth - tBodyWidth : 0;
              headerBody.forEach(r => {
                //adjust the min width of the body columns to match the width actually required after table render
                r.style.minWidth = tBodyOuterWidth + margin + 1 + 'px';
              });
          }
        }
    }
}

  getCardBodyStyle = (displayStyle) => {
    return {
      ...(displayStyle === 'table' && styles.noPadding)
    };
  };

  render = () => {
    const {
      change_type,
      change_type_options,
      search_type_options,
      relative_time_unit_options,
      relative_time_number,
      relative_time_exceeded,
      absolute_selected,
      record_display_style,
      date_range_start,
      date_range_end,
      keywords,
      loading_data_centers,
      entity_results_data_centers,
      loading_services,
      entity_results_services,
      loading_cis,
      entity_results_cis,
      setChangeTypeOptions,
      setSearchTypeOptions,
      setRelativeTimeUnitOptions,
      setStateField,
      submitEntitySearch,
      submitSearchChanges,
      resetFilters,
      selected_data_centers,
      selected_services,
      selected_cis,
      sort_by,
      sort_direction,
      page_number,
      results_per_page,
      searchChangeLoading,
      maxMonthsDateRange,
      error,
      errorMessage
    } = this.props;
    return (
      <div>
        <Row>
          <Col className='mb-4 pl-0' sm={12} md={3} lg={3}>
            <Card className='card-shadow change-history-filter-card mainCard'>
              <FilterTile
                change_type                 = {change_type}
                change_type_options         = {change_type_options}
                search_type_options         = {search_type_options}
                relative_time_unit_options  = {relative_time_unit_options}
                relative_time_number        = {relative_time_number}
                relative_time_exceeded      = {relative_time_exceeded}
                absolute_selected           = {absolute_selected}
                date_range_start            = {date_range_start}
                date_range_end              = {date_range_end}
                keywords                    = {keywords}
                selected_data_centers       = {selected_data_centers}
                selected_services           = {selected_services}
                selected_cis                = {selected_cis}
                sort_by                     = {sort_by}
                sort_direction              = {sort_direction}
                page_number                 = {page_number}
                results_per_page            = {results_per_page}
                loading_data_centers        = {loading_data_centers}
                entity_results_data_centers = {entity_results_data_centers}
                loading_services            = {loading_services}
                entity_results_services     = {entity_results_services}
                loading_cis                 = {loading_cis}
                entity_results_cis          = {entity_results_cis}
                max_months_date_range       = {maxMonthsDateRange}
                // methods
                setChangeTypeOptions        = {setChangeTypeOptions}
                setSearchTypeOptions        = {setSearchTypeOptions}
                setRelativeTimeUnitOptions  = {setRelativeTimeUnitOptions}
                submitEntitySearch          = {submitEntitySearch}
                setStateField               = {setStateField}
                submitSearchChanges         = {submitSearchChanges}
                resetFilters                = {resetFilters}
              />
            </Card>
          </Col>
          <Col className='mb-2 mr-0 px-0 change-history-table-card' sm={12} md={9} lg={9}>
            <Card className='card-shadow mainCard'>
              {/* Main ChangeHistory Card Header */}
              <ChangeHistoryTileHeader
                search_change_is_loading = {searchChangeLoading}
                record_display_style     = {record_display_style}
                sort_direction           = {sort_direction}
                // methods
                setStateField            = {setStateField}
                submitSearchChanges      = {submitSearchChanges}
              />
              {/* Main ChangeHistory Card Body */}
              <Card.Body style={this.getCardBodyStyle(record_display_style)}>
                <ChangeHistoryTileBody
                  we_have_api_results       = {this.props.apiResultsLoaded}
                  search_change_is_loading  = {searchChangeLoading}
                  search_change_response    = {this.props.searchChangeResponse}
                  record_display_style      = {record_display_style}
                  change_type_options       = {change_type_options}
                  error                     = {error}
                  errorMessage              = {errorMessage}
                />
              </Card.Body>
              {/* Main ChangeHistory Card Footer */}
              <ChangeHistoryTileFooter
                we_have_api_results    = {this.props.apiResultsLoaded}
                search_change_response = {this.props.searchChangeResponse}
                page_number            = {this.props.page_number}
                // methods
                submitSearchChanges    = {submitSearchChanges}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeHistory);

