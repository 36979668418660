/* ========= PACKAGE IMPORTS ========= */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link }     from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Row } from 'react-bootstrap';
/* ========= REDUX IMPORTS ========= */
import { actions as securityIncidentActions } from '../../ducks/securityIncidents/securityIncident.index';
import SortTable from './../Tables/SortTable';
import CreateSecurityIncidentModal from '../SecurityIncidents/Modals/CreateSecurityIncidentModal';
import { useTranslation } from 'react-i18next';
import { MdExpandMore } from "react-icons/md";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import './breadcrumbs.css'
import UpdateSecurityIncidentModal from '../SecurityIncidents/Modals/UpdateSecurityIncidentModal';
import DeleteModalTemplate from '../SecurityIncidents/Modals/DeleteModalTemplate';
/* ========= COMPONENT IMPORTS ========= */
import Loading            from '../../components/loading';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import {
  dateTimeToUTCString,
  sortOpi,
  newSortDate,
} from '../../utils/formatFunctions'

/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({ securityIncidentState }) => ({
    securityIncidents : securityIncidentState.securityIncidents,
    securityIncidentsLoading : securityIncidentState.securityIncidentsLoading,
});

const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    requestSecurityIncidents  : securityIncidentActions.requestSecurityIncidents,
    requestCommTypes          : securityIncidentActions.requestCommTypes,
    requestComms              : securityIncidentActions.requestComms,
    requestEmails       : securityIncidentActions.requestEmails,
    requestDeleteSecurityIncident :  securityIncidentActions.requestDeleteSecurityIncident,
  };
  return bindActionCreators(combinedActionCreators, dispatch);
};

const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
    'minWidth' : '73vw',
  },
  'pageTitle': {
    'textAlign'    :  'center',
    'marginLeft'   :  'auto',
    'marginRight'  :  'auto',
    'fontSize'     :  '2rem',
  },
  'evenRow':{
    'backgroundColor': '#007bff2e'
  },
  'tableContainer': {
    'fontSize'        :  '.94rem',
    'boxSizing'       :  'border-box',
    'borderRadius'    :  '4px',
    'backgroundColor' :  'rgb(250, 250, 250)',
    'boxShadow'       :  'rgba(0, 0, 0, 0.2) 0px 0px 10px -2px',
  },
  'noData': {
    'textAlign': 'center',
  }
};

const requiredAction = 'view_security_incidents';
const SecurityIncidents = ({securityIncidentsLoading, securityIncidents, requestDeleteSecurityIncident, requestSecurityIncidents}) => {

  React.useEffect(() => {
    requestSecurityIncidents();
  }, []);
  const { topContainer, pageTitle, headers } = styles;
  const { t } = useTranslation(["translation"]);
  const columns = React.useMemo(
    () => [
        {
          Header: <b style={styles.test} data-cy='id-header'>Incident ID</b>,
          headerStyle: headers,
          id: 'sec_id',
          size: '122px',
          Cell          : ({value}) => <div>{value}</div>,
          accessor      : "sec_id",
          sortMethod    : (a, b) => sortOpi(a, b),
        },
        {
          Header      : <b data-cy="internal-header">Title</b>,
          headerStyle : headers,
          id          : 'title',
          size       : '100px',
          accessor    : data => <div style={{width: '100px'}}>{data.title}</div>,
        },
        {
          Header: <b data-cy='service-header'>Status</b>,
          headerStyle: headers,
          id: 'status',
          accessor    : data => <div style={{width: '100px'}}>{data.status}</div>,
          size: '260px',
        },
        {
          Header: <b data-cy="start-date-header">{t('containers.incidentManagementTable.start')}</b> ,
          headerStyle: headers,
          id: 'start_date',
          accessor: 'start_date',
          size: '230px',
          Cell      : ({value}) => (<div style={{width: '230px'}}>{dateTimeToUTCString(value)}</div>),
          sortType    :  newSortDate
        },
        {
          Header: <b data-cy="start-date-header">{t('containers.incidentManagementTable.end')}</b> ,
          headerStyle: headers,
          id: 'end_date',
          accessor: 'end_date',
          size: '230px',
          Cell      : ({value}) => (<div style={{width: '230px'}}>{dateTimeToUTCString(value)}</div>),
          sortType    :  newSortDate
        },
        {
          Header      : <b data-cy="internal-header">Description</b>,
          headerStyle : headers,
          id          : 'description',
          size       : '100px',
          accessor    : data => <div style={{width: '100px'}}>{data.description}</div>,
        },

        {
          Header: <b data-cy='data-center-header'>Manage Comms</b>,
          headerStyle: headers,
          id: 'sec_id1',
          width: 50,
          accessor: (data) => <Link to={`/comms/${data.sec_id}`} style={{marginLeft: '10px'}} ><MdExpandMore style={{fontSize: "35px"}}/></Link>
        },
        {
          Header: <b data-cy='data-center-header'>Edit</b>,
          headerStyle: headers,
          id: 'sec_id2',
          width: 50,
          accessor: (data) => <UpdateSecurityIncidentModal data={data} />
        },
        {
          Header: <b data-cy='data-center-header'>Delete</b>,
          headerStyle: headers,
          id: 'sec_id3',
          width: 50,
          accessor: (data) => <DeleteModalTemplate 
          delete_id={data.sec_id} 
          data={data} 
          deleteFunction={requestDeleteSecurityIncident} 
          type="Security Incident"
          displayKeys={["Incident ID", "Title", "Status", "Start Date", "Description"]}
          keys={["sec_id", "title", "status", "start_date", "description"]}/>
        },
      ]
    )

    const sort = {
      id: "start_date",
      desc: true
    }
    const widthDict = {
      'id': 122,
      'title': 260,
      'description': 300,
      'start_date': 230,
      'actions': 230
    }

    return (
      <AuthorizationWrapper requiredAction={requiredAction}>
        <div style={topContainer}>
          {
            securityIncidentsLoading
            ? <div>
                <div style={pageTitle}>Loading...</div>
                <Loading height="75vh" />
              </div>
            : <div>

                <Row>
                  <div style={pageTitle}>Security Incidents</div>
                </Row>
                <Row>
                  <Breadcrumb style={{marginTop: 'auto'}}>
                    <Breadcrumb.Item active>Security Incidents</Breadcrumb.Item>
                  </Breadcrumb>
                  <Link style={{marginLeft: 'auto', float: 'right'}} to="/manage_security_users" ><Button>Manage Emails</Button></Link>
                  {<Link to={`/mass_import/`} style={{float: 'right', marginLeft: '1rem'}}><Button>Mail Merge Template Upload</Button></Link>}
                  <CreateSecurityIncidentModal />
                  
                </Row>

                <SortTable data={securityIncidents} columns={columns} defaultSort={sort} widthDict={widthDict}/>
              </div>
          }

        </div>
      </AuthorizationWrapper>
    );
  }

export default connect(mapStateToProps, mapDispatchToProps)(SecurityIncidents);

