import { takeEvery, put, call, select } from "redux-saga/effects";

import {
  getSecurityIncidents, 
  getSecurityUsers,
  getSecurityUsersImpact,
  getCommTypes,
  getComms,
  getCommsId,
  getEmails,
  getImports,
  getStagingEmails,
  getSpecificEmails,
  createSecurityIncident,
  updateSecurityIncident,
  deleteSecurityIncident,
  createComm,
  updateComm,
  deleteComm,
  createEmails,
  updateEmails,
  deleteEmails,
  createSecurityUsers,
  updateSecurityUsers,
  deleteSecurityUsers,
  createImports,
  updateImports,
  deleteImports,
  createStagingEmails,
  updateStagingEmails,
  promoteStagingEmails,
  deleteStagingEmails,
  sendEmails,
 } from './securityIncident.api';
import { types as securityIncidentTypes, actions as securityIncidentActions } from './securityIncident.index';
import { actions as uiActions }     from '../ui/ui.index';
import { actions as errorActions }  from '../error/error.index';
import { actions as toastActions }  from '../toast/toast.index';
import {selectors} from '../user/user.index';
//defaulted to 1000 in openmt but will pass in so front end will have control
const SECURITY_USERS_RESPONSE_SIZE = 200;


export function* fetchSecurityIncidents() {
  try {
    // call endpoint
    yield put(securityIncidentActions.setSecurityIncidentsLoading(true));
    let response =  yield call(getSecurityIncidents);
    let payload  =  response.data.payload;

    // update state
    yield put(securityIncidentActions.setSecurityIncidents(payload));
    yield put(securityIncidentActions.setAllSecIds());
    yield put(securityIncidentActions.setSecurityIncidentsLoading(false));
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setSecurityIncidentsLoading(false));
    const message      =  `Unable to get security incidents: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}



export function* fetchSecurityUsers(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setSecurityUsersLoading(true));
    let response =  yield call(getSecurityUsers, 0, SECURITY_USERS_RESPONSE_SIZE, action.payload.email, action.payload.company_id);
    yield put(securityIncidentActions.setSecurityUsers(response.data.users));
    yield put(securityIncidentActions.setSecurityUsersLoading(false));
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setSecurityUsersLoading(false));
    const message      =  `Unable to get security users: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchSecurityUsersImpact(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setSecurityUsersLoading(true));
    let response =  yield call(getSecurityUsersImpact, action.payload);
    yield put(securityIncidentActions.setSecurityUsersImpact(response.data.data));
    yield put(securityIncidentActions.setSecurityUsersLoading(false));
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setSecurityUsersLoading(false));
    const message      =  `Unable to get security user impact: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }

}


export function* fetchCommTypes() {
  try {
    // call endpoint
    yield put(securityIncidentActions.setCommTypesLoading(true));
    let response =  yield call(getCommTypes);
    let payload  =  response.data.payload;

    // update state
    yield put(securityIncidentActions.setCommTypes(payload));
    yield put(securityIncidentActions.setCommTypesLoading(false));
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setCommTypesLoading(false));
    const message      =  `Unable to get comm types: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchComms(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setCommsLoading(true));
    let response = {}
    if (action.payload){
      response =  yield call(getCommsId, action.payload);
    }
    else{
      response =  yield call(getComms);
    }
    let payload  =  response.data.payload;

    // update state
    yield put(securityIncidentActions.setComms(payload));
    yield put(securityIncidentActions.setAllCommIds());
    yield put(securityIncidentActions.setCommsLoading(false));
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setCommsLoading(false));
    const message      =  `Unable to get comms: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchEmails(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setEmailsLoading(true));
    let response =  yield call(getEmails, action.payload);
    let payload  =  response.data.payload;

    // update state
    yield put(securityIncidentActions.setEmails(payload));
    yield put(securityIncidentActions.setEmailsLoading(false));
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setEmailsLoading(true));
    const message      =  `Unable to get emails: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchSpecificEmails(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setSpecificEmailsLoading(true));
    let response =  yield call(getSpecificEmails, action.payload);
    let payload  =  response.data.payload;

    // update state
    yield put(securityIncidentActions.setEmails(payload));
    yield put(securityIncidentActions.setSpecificEmailsLoading(false));
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setSpecificEmailsLoading(true));
    const message      =  `Unable to get emails: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchImports(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setImportsLoading(true));
    let response =  yield call(getImports, action.payload);
    let payload  =  response.data.payload;

    // update state
    yield put(securityIncidentActions.setImports(payload));
    yield put(securityIncidentActions.setImportsLoading(false));
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setImportsLoading(true));
    const message      =  `Unable to get imports: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchStagingEmails(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setStagingEmailsLoading(true));
    let response =  yield call(getStagingEmails, action.payload);
    let payload  =  response.data.payload;
    // update state
    yield put(securityIncidentActions.setStagingEmails(payload));
    yield put(securityIncidentActions.setStagingEmailsLoading(false));
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setStagingEmailsLoading(true));
    const message      =  `Unable to get staging emails: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitCreateSecurityIncident(action) {
  try{
    // extract relevant variables
    yield put(securityIncidentActions.setSecurityIncidentsLoading(true));
    const payload =  action.payload;
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Create Security Incident"
    }
    // submit it to changeEntity api endpoint
    const response = yield call(createSecurityIncident, payload);
    payload['sec_id'] = response.data.sec_id;
    payload['security_comms'] = [];
    payload['created_date'] = new Date().toISOString();
    yield put(securityIncidentActions.updateAddSecurityIncident(payload));

    yield put(securityIncidentActions.setSecurityIncidentsLoading(false));
    yield put(toastActions.createToast('Security Incident created successfully!', {type: 'success'}))
  }
  catch (error) {
    const message      =  `There was an issue creating the Security Incident. Please try again. ${error}`;
    if (message.includes('504')){
      yield put(securityIncidentActions.setSecurityIncidentsLoading(false));
      yield put(securityIncidentActions.requestSecurityIncidents());
      yield put(toastActions.createToast('Security Incident Not Created!', {type: 'error'}))
    }
    else{
      yield put(securityIncidentActions.setSecurityIncidentsLoading(false));
      const toastOptions =  {type: 'error', autoClose: false};
      yield put(toastActions.createToast(message, toastOptions));
    }
  }
}


export function* submitCreateComm(action) {
  try{
    // extract relevant variables
    yield put(securityIncidentActions.setCommsLoading(true));
    const payload =  action.payload;
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Create Comm"
    }
    // submit it to changeEntity api endpoint
    const response = yield call(createComm, payload);
    payload['sec_comm_id'] = response.data.sec_comm_id;
    payload['created_date'] = new Date().toISOString();
    payload['comm_type'] = parseInt(payload['comm_type']);
    yield put(securityIncidentActions.updateAddComm(payload));
    yield put(securityIncidentActions.setCommsLoading(false));
    yield put(toastActions.createToast('Comm created successfully!', {type: 'success'}))
  }                  
  catch (error) {
    const message      =  `There was an issue creating the Comm. Please try again. ${error}`;
    if (message.includes('504')){
      yield put(securityIncidentActions.setCommsLoading(false));
      yield put(toastActions.createToast('Comm Not Created!', {type: 'error'}))
    }
    else{
      const toastOptions =  {type: 'error', autoClose: false};
      yield put(toastActions.createToast(message, toastOptions));
    }
  }
}


export function* submitCreateEmails(action) {
  try{
    // extract relevant variables
    yield put(securityIncidentActions.setEmailsLoading(true));
    const payload =  action.payload;
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Create Email"
    }
    // submit it to changeEntity api endpoint
    const response = yield call(createEmails, payload);
    yield put(securityIncidentActions.requestEmails(payload.sec_comm_id));
    yield put(securityIncidentActions.setEmailsLoading(false));
    yield put(toastActions.createToast('Email created successfully!', {type: 'success'}))
  }                  
  catch (error) {
    const message      =  `There was an issue creating the Comm. Please try again. ${error}`;
    if (message.includes('504')){
      yield put(securityIncidentActions.setEmailsLoading(false));
      yield put(toastActions.createToast('Emails not created!', {type: 'error'}))
    }
    else{
      const toastOptions =  {type: 'error', autoClose: false};
      yield put(toastActions.createToast(message, toastOptions));
    }
  }
}

export function* submitCreateSecurityUsers(action) {
  try{
    // extract relevant variables
    yield put(securityIncidentActions.setSecurityUsersLoading(true));
    const payload =  action.payload;
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Create Security User"
    }
    // submit it to changeEntity api endpoint
    const response = yield call(createSecurityUsers, payload);
    payload['id'] = response.data.user_id;
    payload['created_date'] = new Date().toISOString();
    yield put(securityIncidentActions.updateAddSecurityUser(payload));
    yield put(securityIncidentActions.setSecurityUsersLoading(false));
    yield put(toastActions.createToast('Security User created successfully!', {type: 'success'}))
  }                  
  catch (error) {
    const message      =  `There was an issue creating the Security User. Please try again. ${error}`;
    if (message.includes('504')){
      yield put(securityIncidentActions.setSecurityUsersLoading(false));
      yield put(toastActions.createToast('Security User not created!', {type: 'error'}))
    }
    else{
      const toastOptions =  {type: 'error', autoClose: false};
      yield put(toastActions.createToast(message, toastOptions));
    }
  }

}

export function* submitCreateImports(action) {
  try{
    // extract relevant variables
    yield put(securityIncidentActions.setImportsLoading(true));
    const payload =  action.payload;
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Create Security Import"
    }
    // submit it to changeEntity api endpoint
    const response = yield call(createImports, payload);
    console.log('response', response)
    payload['pkey'] = response.data.import_id;
    payload['created_date'] = new Date().toISOString();
    yield put(securityIncidentActions.updateAddImport(payload));
    yield put(securityIncidentActions.setImportsLoading(false));
    yield put(toastActions.createToast('Security Import created successfully!', {type: 'success'}))
  }                  
  catch (error) {
    const message      =  `There was an issue creating the Security Import. Please try again. ${error}`;
    if (message.includes('504')){
      yield put(securityIncidentActions.setImportsLoading(false));
      yield put(toastActions.createToast('Security Import not created!', {type: 'error'}))
    }
    else{
      const toastOptions =  {type: 'error', autoClose: false};
      yield put(toastActions.createToast(message, toastOptions));
    }
  }
}

export function* submitCreateStagingEmails(action) {
  try{
    // extract relevant variables
    yield put(securityIncidentActions.setStagingEmailsLoading(true));
    const payload =  action.payload;
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Create Staging Email"
    }
    // submit it to changeEntity api endpoint
    const response = yield call(createStagingEmails, payload);
    // payload['id'] = response.data.user_id;
    // payload['created_date'] = new Date().toISOString();
    //yield put(securityIncidentActions.updateAddSecurityUser(payload));
    yield put(securityIncidentActions.setSTagingEmails(payload));
    yield put(securityIncidentActions.setStagingEmailsLoading(false));
    yield put(toastActions.createToast('Staging Emails created successfully!', {type: 'success'}))
  }                  
  catch (error) {
    const message      =  `There was an issue creating the staging emails. Please try again. ${error}`;
    if (message.includes('504')){
      yield put(securityIncidentActions.setStagingEmailsLoading(false));
      yield put(toastActions.createToast('Staging Emails not created!', {type: 'error'}))
    }
    else{
      const toastOptions =  {type: 'error', autoClose: false};
      yield put(toastActions.createToast(message, toastOptions));
    }
  }
}


export function* submitUpdateSecurityIncident(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setSecurityIncidentsLoading(true));
    const payload =  action.payload;
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Update Security Incident"
    }
    yield call(updateSecurityIncident, payload)
    yield put (securityIncidentActions.updateOneSecurityIncident(payload));
    yield put(securityIncidentActions.setSecurityIncidentsLoading(false));
    yield put(toastActions.createToast('Security Incident updated successfully!', {type: 'success'}))
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setSecurityIncidentsLoading(false));
    const message      =  `Unable to update security incident state: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitUpdateComm(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setCommsLoading(true));
    const payload =  action.payload;
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Update Comm"
    }
    yield call(updateComm, payload);
    yield put (securityIncidentActions.updateOneComm(payload));
    yield put(securityIncidentActions.setCommsLoading(false));
    yield put(toastActions.createToast('Comm updated successfully!', {type: 'success'}))
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setCommsLoading(false));
    const message      =  `Unable to update comm state: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitUpdateEmails(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setEmailsLoading(true));
    const payload =  action.payload;
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Update Email"
    }
    yield call(updateEmails, payload);
    yield put (securityIncidentActions.updateOneCommContent(payload));
    yield put(securityIncidentActions.setEmailsLoading(false));
    yield put(toastActions.createToast('Emails updated successfully!', {type: 'success'}))
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setEmailsLoading(false));
    const message      =  `Unable to update email state: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitUpdateImports(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setImportsLoading(true));
    const payload =  action.payload;
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Update Import"
    }
    yield call(updateImports, payload);
    //yield put (securityIncidentActions.updateOneCommContent(payload));
    yield put(securityIncidentActions.setImportsLoading(false));
    yield put(toastActions.createToast('imports updated successfully!', {type: 'success'}))
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setImportsLoading(false));
    const message      =  `Unable to update imports state: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitUpdateStagingEmails(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setStagingEmailsLoading(true));
    const payload =  action.payload;
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Update Staging Emails"
    }
    yield call(updateStagingEmails, payload);
    yield put (securityIncidentActions.updateOneStagingEmail(payload));
    yield put(securityIncidentActions.setStagingEmailsLoading(false));
    yield put(toastActions.createToast('Staging emails updated successfully!', {type: 'success'}))
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setStagingEmailsLoading(false));
    const message      =  `Unable to update staging emails state: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitPromoteStagingEmails(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setStagingEmailsLoading(true));
    const payload =  action.ids;
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Update Promote Emails"
    }
    let response = yield call(promoteStagingEmails, payload);
    yield put (securityIncidentActions.updatePromoteStagingEmail(payload));
    yield put(securityIncidentActions.setStagingEmailsLoading(false));
    yield put(toastActions.createToast('Staging emails promoted successfully!', {type: 'success'}))
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setStagingEmailsLoading(false));
    const message      =  `Unable to promote staging emails state: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitUpdateSecurityUsers(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setSecurityUsersLoading(true));
    const payload =  action.payload;
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Update Security User"
    }
    yield call(updateSecurityUsers, payload);
    yield put(securityIncidentActions.updateOneSecurityUser(payload));
    yield put(securityIncidentActions.setSecurityUsersLoading(false));
    yield put(toastActions.createToast('Security Users updated successfully!', {type: 'success'}))
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setSecurityUsersLoading(false));
    const message      =  `Unable to update security users state: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitDeleteSecurityIncident(action) {
  try{
    yield put(securityIncidentActions.setSecurityIncidentsLoading(true));
    const payload =  {sec_id: action.payload};
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Delete Security Incident"
    }
    const response = yield call(deleteSecurityIncident, payload);
    yield put(securityIncidentActions.updateDeleteSecurityIncident(action.payload));
    yield put(securityIncidentActions.setSecurityIncidentsLoading(false));
    yield put(toastActions.createToast('Security Incident deleted successfully!', {type: 'success'}))
  }
  catch (error) {
    yield put(securityIncidentActions.setSecurityIncidentsLoading(false));
    const message      =  `There was an issue deleting the message. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* submitDeleteComm(action) {
  try{
    yield put(securityIncidentActions.setCommsLoading(true));
    const payload =  {sec_comm_id: action.payload};
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Delete Comm"
    }
    const response = yield call(deleteComm, payload);
    yield put(securityIncidentActions.updateDeleteComm(action.payload));
    yield put(securityIncidentActions.setCommsLoading(false));
    yield put(toastActions.createToast('Comm deleted successfully!', {type: 'success'}))
  }
  catch (error) {
    yield put(securityIncidentActions.setCommsLoading(false));
    const message      =  `There was an issue deleting the Comm. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* submitDeleteEmails(action) {
  try{
    yield put(securityIncidentActions.setEmailsLoading(true));
    const payload =  {comm_content_id: action.payload};
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Delete Email"
    }
    const response = yield call(deleteEmails, payload);
    yield put(securityIncidentActions.updateDeleteCommContent(action.payload));
    yield put(securityIncidentActions.setEmailsLoading(false));
    yield put(toastActions.createToast('Email deleted successfully!', {type: 'success'}))
  }
  catch (error) {
    yield put(securityIncidentActions.setEmailsLoading(false));
    const message      =  `There was an issue deleting the email. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* submitDeleteImports(action) {
  try{
    yield put(securityIncidentActions.setImportsLoading(true));
    const payload =  {import_id: action.payload};
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Delete Security Import"
    }
    const response = yield call(deleteImports, payload);
    yield put(securityIncidentActions.updateDeleteImport(action.payload));
    yield put(securityIncidentActions.setImportsLoading(false));
    yield put(toastActions.createToast('Import deleted successfully!', {type: 'success'}))
  }
  catch (error) {
    yield put(securityIncidentActions.setImportsLoading(false));
    const message      =  `There was an issue deleting the import. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* submitDeleteStagingEmails(action) {
  try{
    yield put(securityIncidentActions.setStagingEmailsLoading(true));
    const payload =  {staging_id: action.payload};
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Delete Staging Emails"
    }
    const response = yield call(deleteStagingEmails, payload);
    yield put(securityIncidentActions.updateDeleteStagingEmail(action.payload));
    yield put(securityIncidentActions.setStagingEmailsLoading(false));
    yield put(toastActions.createToast('staging email deleted successfully!', {type: 'success'}))
  }
  catch (error) {
    yield put(securityIncidentActions.setStagingEmailsLoading(false));
    const message      =  `There was an issue deleting the staging emails. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* submitDeleteSecurityUsers(action) {
  try{
    yield put(securityIncidentActions.setSecurityUsersLoading(true));
    const payload =  {user_id: action.payload};
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Delete Security Users"
    }
    const response = yield call(deleteSecurityUsers, payload);
    yield put(securityIncidentActions.updateDeleteSecurityUser(action.payload));
    yield put(securityIncidentActions.setSecurityUsersLoading(false));
    yield put(toastActions.createToast('Security User deleted successfully!', {type: 'success'}))
  }
  catch (error) {
    yield put(securityIncidentActions.setSecurityUsersLoading(false));
    const message      =  `There was an issue deleting the Security User. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}


export function* submitSendEmails(action) {
  try{ 
    // extract relevant variables
    yield put(securityIncidentActions.setEmailsLoading(true));
    const payload =  action.payload;
    const userEmail = yield select(selectors.getUserEmail);
    payload.audit_log_data = {
      'application_name':"Aviary",
      'user_email':userEmail,
      'action_name':"Security Incident Send Emails"
    }
    const response = yield call(sendEmails, payload);
    const state = yield select();
    let emails = state.securityIncidentState.emails;
    let sentEmails = emails.filter(email => payload['email_ids'].includes(email.id));
    for(let i = 0; i < sentEmails.length; i++){
      sentEmails[i]['sent_date'] = new Date().toISOString();
      sentEmails[i]['was_sent'] = 'Y';
      yield put (securityIncidentActions.updateOneCommContent(sentEmails[i]));
    }
    yield put(securityIncidentActions.setEmailsLoading(false));
    yield put(toastActions.createToast('Email sent successfully!', {type: 'success'}))
  }                  
  catch (error) {
    const message      =  `There was an issue sending the email. Please try again. ${error}`;
    if (message.includes('504')){
      yield put(securityIncidentActions.setEmailsLoading(false));
      yield put(toastActions.createToast('Email Not sent Created!', {type: 'error'}))
    }
    else{
      const toastOptions =  {type: 'error', autoClose: false};
      yield put(securityIncidentActions.setEmailsLoading(false));
      yield put(toastActions.createToast(message, toastOptions));
    }
  }
}

// watcher saga
export default function securityIncidentSagas() {
  return [
    takeEvery(securityIncidentTypes.REQUEST_SECURITY_INCIDENTS, fetchSecurityIncidents),
    takeEvery(securityIncidentTypes.REQUEST_SECURITY_USERS, fetchSecurityUsers),
    takeEvery(securityIncidentTypes.REQUEST_SECURITY_USERS_IMPACT, fetchSecurityUsersImpact),
    takeEvery(securityIncidentTypes.REQUEST_COMM_TYPES, fetchCommTypes),
    takeEvery(securityIncidentTypes.REQUEST_COMMS, fetchComms),
    takeEvery(securityIncidentTypes.REQUEST_EMAILS, fetchEmails),
    takeEvery(securityIncidentTypes.REQUEST_IMPORTS, fetchImports),
    takeEvery(securityIncidentTypes.REQUEST_STAGING_EMAILS, fetchStagingEmails),
    takeEvery(securityIncidentTypes.REQUEST_CREATE_SECURITY_INCIDENT, submitCreateSecurityIncident),
    takeEvery(securityIncidentTypes.REQUEST_EDIT_SECURITY_INCIDENT, submitUpdateSecurityIncident),
    takeEvery(securityIncidentTypes.REQUEST_DELETE_SECURITY_INCIDENT, submitDeleteSecurityIncident),
    takeEvery(securityIncidentTypes.REQUEST_CREATE_COMM, submitCreateComm),
    takeEvery(securityIncidentTypes.REQUEST_EDIT_COMM, submitUpdateComm),
    takeEvery(securityIncidentTypes.REQUEST_DELETE_COMM, submitDeleteComm),
    takeEvery(securityIncidentTypes.REQUEST_CREATE_EMAILS, submitCreateEmails),
    takeEvery(securityIncidentTypes.REQUEST_EDIT_EMAILS, submitUpdateEmails),
    takeEvery(securityIncidentTypes.REQUEST_DELETE_EMAILS, submitDeleteEmails),
    takeEvery(securityIncidentTypes.REQUEST_SPECIFIC_EMAILS, fetchSpecificEmails),
    takeEvery(securityIncidentTypes.REQUEST_CREATE_SECURITY_USERS, submitCreateSecurityUsers),
    takeEvery(securityIncidentTypes.REQUEST_EDIT_SECURITY_USERS, submitUpdateSecurityUsers),
    takeEvery(securityIncidentTypes.REQUEST_DELETE_SECURITY_USERS, submitDeleteSecurityUsers),
    takeEvery(securityIncidentTypes.REQUEST_CREATE_IMPORTS, submitCreateImports),
    takeEvery(securityIncidentTypes.REQUEST_EDIT_IMPORTS, submitUpdateImports),
    takeEvery(securityIncidentTypes.REQUEST_DELETE_IMPORTS, submitDeleteImports),
    takeEvery(securityIncidentTypes.REQUEST_CREATE_STAGING_EMAILS, submitCreateStagingEmails),
    takeEvery(securityIncidentTypes.REQUEST_EDIT_STAGING_EMAILS, submitUpdateStagingEmails),
    takeEvery(securityIncidentTypes.REQUEST_PROMOTE_STAGING_EMAILS, submitPromoteStagingEmails),
    takeEvery(securityIncidentTypes.REQUEST_DELETE_STAGING_EMAILS, submitDeleteStagingEmails),
    takeEvery(securityIncidentTypes.REQUEST_SEND_EMAILS, submitSendEmails),

  ];
}
