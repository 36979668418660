import React, {useEffect}      from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Row } from 'react-bootstrap';
import { Link }     from 'react-router-dom';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import { actions as securityIncidentActions } from '../../ducks/securityIncidents/securityIncident.index';
import Loading            from '../../components/loading';
import { MdExpandMore } from "react-icons/md";
import SortTable from '../Tables/SortTable';
import CreateCommModal from './Modals/CreateCommModal';
import UpdateCommModal from './Modals/UpdateCommModal';
import DeleteModalTemplate from './Modals/DeleteModalTemplate';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import './breadcrumbs.css'
import {
  dateTimeToUTCString,
  sortOpi,
  newSortDate,
} from '../../utils/formatFunctions'

const mapStateToProps = ({securityIncidentState}, props) => {
  let securityIncidentId;
  securityIncidentId = props.match.params['id'];
  return {
    securityIncidentId: securityIncidentId,
    commsLoading      : securityIncidentState.commsLoading,
    commTypesLoading      : securityIncidentState.commTypesLoading,
    comms             : securityIncidentState.comms,
    commTypes: securityIncidentState.commTypes,
  }
};

const mapDispatchToProps = (dispatch) => {
const combinedActionCreators = {
  requestComms              : securityIncidentActions.requestComms,
  requestCommTypes          : securityIncidentActions.requestCommTypes,
  requestDeleteComm        : securityIncidentActions.requestDeleteComm,
};
return bindActionCreators(combinedActionCreators, dispatch);
};
const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
    'minWidth': '73vw',
  },
  'pageTitle': {
    'marginLeft'   :  'auto',
    'marginRight'  :  'auto',
    'textAlign'    :  'center',
    'fontSize'     :  '2rem',
  },
};

const requiredAction = 'view_comms';

const Comms = ({requestComms, requestCommTypes, commsLoading, commTypesLoading, securityIncidentId, comms, commTypes, requestDeleteComm}) => {
  const { topContainer, pageTitle, headers } = styles;
  useEffect(() => {
    requestComms(securityIncidentId);
    requestCommTypes();
  }, []);

  function getCommType(comm_id) {
    const comm_type = commTypes.find(commType => commType.id === comm_id);
    return comm_type ? comm_type.display_name : '';
  }

  const columns = React.useMemo(
    () => [
        {
          Header: <b style={styles.test} data-cy='id-header'>Comm ID</b>,
          headerStyle: headers,
          id: 'sec_comm_id',
          size: '122px',
          Cell          : ({value}) => <div>{value}</div>,
          accessor      : "sec_comm_id",
          sortMethod    : (a, b) => sortOpi(a, b),
        },
        {
          Header      : <b data-cy="internal-header">Comm Type</b>,
          headerStyle : headers,
          id          : 'comm_type',
          size       : '100px',
          accessor    : data => <div style={{width: '100px'}}>{getCommType(data.comm_type)}</div>,
        },
        {
          Header: <b data-cy='service-header'>Status</b>,
          headerStyle: headers,
          id: 'status',
          accessor    : data => <div style={{width: '100px'}}>{data.status}</div>,
          size: '260px',
        },
        {
          Header: <b data-cy="created-date-header">Created Date</b> ,
          headerStyle: headers,
          id: 'created_date',
          accessor: 'created_date',
          size: '230px',
          Cell      : ({value}) => (<div style={{width: '230px'}}>{dateTimeToUTCString(value)}</div>),
          sortType: newSortDate,
        },
        {
          Header      : <b data-cy="internal-header">Description</b>,
          headerStyle : headers,
          id          : 'description',
          size       : '100px',
          accessor    : data => <div style={{width: '100px'}}>{data.description}</div>,
        },
        {
          Header: <b data-cy='data-center-header'>Manage Emails</b>,
          headerStyle: headers,
          id: 'sec_comm_id1',
          width: 50,
          accessor: (data) => <Link to={`/emails/${data.sec_comm_id}`} style={{marginLeft: '10px'}} ><MdExpandMore style={{fontSize: "35px"}}/></Link>
        },
        {
          Header: <b data-cy='data-center-header'>Edit</b>,
          headerStyle: headers,
          id: 'sec_comm_id2',
          width: 50,
          accessor: (data) => <UpdateCommModal data={data} comm_types={commTypes} />
        },
        {
          Header: <b data-cy='data-center-header'>Delete</b>,
          headerStyle: headers,
          id: 'sec_comm_id3',
          width: 50,
          accessor: (data) => <DeleteModalTemplate 
          deleteFunction={requestDeleteComm}
          delete_id={data.sec_comm_id} 
          type="Comm"
          data={data} 
          keys={["sec_comm_id", "comm_type", "status", "created_date", "description"]}
          displayKeys={["Comm ID", "Comm Type", "Status", "Created Date", "Description"]} 
          comm_type={getCommType(data.comm_type)}/>
        },
      ]
    )
    const sort = {
      id: "created_date",
      desc: true
    }
    const widthDict = {
      'sec_comm_id': 122,
      'comm_type': 260,
      'description': 300,
      'created_date': 230,
      'status': 230
    }
return (
  <AuthorizationWrapper requiredAction={requiredAction}>
    <div style={topContainer}>
      {
        commsLoading || commTypesLoading
        ? <div>
            <div style={pageTitle}>Loading...</div>
            <Loading height="75vh" />
          </div>
        : <div>
            <Row>
              <Breadcrumb style={{marginTop: 'auto'}}>
                <Breadcrumb.Item href="/security_incidents">Security Incidents</Breadcrumb.Item>
                <Breadcrumb.Item active>Comms</Breadcrumb.Item>
              </Breadcrumb>
              <div style={pageTitle}>Comms for {securityIncidentId}</div>
              <CreateCommModal sec_id={securityIncidentId} comm_types={commTypes}/>
            </Row>
            <SortTable data={comms} columns={columns} defaultSort={sort} widthDict={widthDict}/>
          </div>
      }
    </div>
  </AuthorizationWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Comms);