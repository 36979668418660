import React, { useState } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actions as uiActions,
} from '../../../ducks/ui/ui.index';
import {
  actions as maintenanceEventActions
} from '../../../ducks/v2MaintenanceEvent/v2MaintenanceEvent.index';
import { Modal, Button, Form, Tooltip, OverlayTrigger, Row, Col } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import {Formik, Field} from 'formik';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import infoImage from '../../../assets/282532_Information_R_blue.png';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import AuthorizationWrapper from '../../../components/authorizationWrapper';
/* ========= PACKAGE IMPORTS ========= */

const editAction = 'edit_maintenance_event';

const schema = yup.object().shape({
  is_test: yup.string().required().oneOf(['Y', 'N']),
  broadcast: yup.string().required().oneOf(['Y', 'N']),
  start_date: yup.string().required(),
  end_date: yup.string().required(),
  title: yup.string().required(),
  body: yup.string().required(),
  created_by: yup.string().required(),
  mwin_id: yup.string().required(),
  comm_src: yup.string().required(),
  n_type: yup.string().required(),
  mwin_id: yup.string().required(),
  subject: yup.string().when('broadcast', {
    is: 'Y',
    then: yup.string().required(),
    otherwise: yup.string().optional()
  }),

});

const mapStateToProps = ({uiState, userState, v2MaintenanceEventState, templateState}) => ({
  pageLoading         :  uiState.pageLoading,
  email               :  userState.user.email, 
  all_data_centers    :  v2MaintenanceEventState.all_data_centers,
  templates           :  templateState.notification_templates,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setPageLoading                :  uiActions.setPageLoading,
    V2SubmitCreateNotification      :  maintenanceEventActions.V2SubmitCreateNotification,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const PDT = 420; //7 hours, used if daylight savings is active
const PST = 480; //8 hours, used if daylight savings is not active
const MILISECOND_CONVERT = 60000;

const SUBJECT_DICT = {
  "Maintenance Announcement": "Data Center Maintenance ",
  "Maintenance Update": "[Update] Data Center Maintenance ",
  "Maintenance Complete": "[Complete] Data Center Maintenance ",
  "Maintenance Cancelled": "[Cancelled] Data Center Maintenance ",
}

const CreateNotificationModal = ({V2SubmitCreateNotification, setPageLoading, email, mwin_id, mwin_start_date, mwin_end_date, templates}) => {
  const [timezone, setTimezone] = useState('Etc/Greenwich');
  const { t } = useTranslation(["translation"]);
  const [startDate, setStartDate] = useState(new Date(new Date().toLocaleString('en', {timeZone: 'America/Los_Angeles'})));
  const isDST = () => {
    return moment.tz('America/Los_Angeles').isDST();
  }
  const dstActive = isDST();
  const tzIdentifier = dstActive ? "-0700" : "-0800";
  const pacificTimeOffset = dstActive ? PDT : PST;
  const curOffset = startDate.getTimezoneOffset();
  const offsetDif = curOffset - pacificTimeOffset;
  const [sendTime, setSendTime] = useState(new Date(new Date(new Date(new Date().setHours(new Date().getHours() + 3))).toLocaleString('en', {timeZone: 'America/Los_Angeles'})));
  const [endDate, setEndDate] = useState(new Date(new Date(new Date().setHours(new Date().getHours() + 3)).toLocaleString('en', {timeZone: 'America/Los_Angeles'})));
  const [formattedStartDate, setFormattedStartDate] = useState(moment(new Date().toLocaleString('en', {timeZone: timezone})).format('MM/DD/YYYY, hh:mm A'))
  const [formattedEndDate, setFormattedEndDate] = useState(moment(new Date(new Date().setHours(new Date().getHours() + 3)).toLocaleString('en', {timeZone: timezone})).format('MM/DD/YYYY, hh:mm A'))
  const [flag, setFlag] = useState('N');
  const [broadcast, setBroadcast] = useState('N');
  const [title, setTitle] = useState('');
  const [nType, setNType] = useState('Maintenance Announcement');
  const [subject, setSubject] = useState('Data Center Maintenance ' + moment(mwin_start_date).format('MMMM D, YYYY'));
  const [notificationBody, setNotificationBody] = useState('');
  let styles = {
    error         :  {
      color       :  'red',
    },
    buttonError   :  {
      color       :  'red',
      marginLeft  :  '5px',
    },
    button        : {
      width       :  '8rem',
    },
    buttonRight   : {
      width       : '8rem',
      margin      : '1rem'
    },
    buttonLeft: {
      width       : '8rem',
      margin      : '1rem auto 1rem 1rem',
      float       : 'left'
    },
    buttonRightDisabled: {
      width       : '8rem',
      marginLeft  : '1rem',
      cursor      : 'default'
    },
    title: {
      textAlign: 'center',
      color: 'red',
    },
    marginRight: {
      marginRight: '1rem',
    },
    confirmationText: {
      marginLeft: '.5rem',
      marginBottom: '.5rem',
      marginTop: '.5rem',
    },
    confirmationHeader: {
      marginBottom: '.5rem',
      marginTop: '.5rem',
      minWidth: '120px'
    },
    infoImg: {
      maxHeight : '1.9rem',
    },
    inlineMiddleVAlign: {
      display : 'inline',
      verticalAlign : 'middle'
    },
  }
  const [modalIsOpen,setIsOpen] = useState(false);
  const [showConfirmation,setShowConfirmation] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const resetValues = () => {
    setTitle('');
    setNotificationBody('');
    setEndDate(new Date(new Date(new Date().setHours(new Date().getHours() + 3)).toLocaleString('en', {timeZone: 'America/Los_Angeles'})));
    setStartDate(new Date(new Date().toLocaleString('en', {timeZone: 'America/Los_Angeles'})));
    setSendTime(new Date(new Date(new Date(new Date().setHours(new Date().getHours() + 3))).toLocaleString('en', {timeZone: 'America/Los_Angeles'})))
    setFlag('');
    setNType('Maintenance Announcement');
    setSubject('Data Center Maintenance ' + moment(mwin_start_date).format('MMMM D, YYYY'));
    setBroadcast('N');
    setSubject('');
  }

  const formatPstDate = (date) => {
    let tempDate = moment(date).format('YYYY-MM-DDTHH:mm:ssZZ');
    return tempDate.slice(0, -5) + tzIdentifier;
  }

  const submitFunc = (values) => {
    if (values.n_type != 'Personalized Concur Open'){
      delete values.start_date;
      delete values.end_date;
    }
    else{
      values.start_date = formatPstDate(values.start_date)
      values.end_date = formatPstDate(values.end_date)
    }
    if (values.broadcast == 'Y' && (values.n_type == 'Maintenance Update' || values.n_type == 'Maintenance Cancelled' || values.n_type == 'Maintenance Complete' || values.n_type == 'Maintenance Announcement')){
      values.send_time = formatPstDate(values.send_time);
    }
    else{
      delete values.send_time
    }
    V2SubmitCreateNotification(values);
    setShowConfirmation(false);
    setPageLoading(false);
    resetValues();
    closeModal();
  }

  //custom on change logic
  const onChangeBroadcast = (e, values, field, setValues) => {
    const message_type = values.message_type;
    const inputValue = e.target.value;
    setValues({...values,  email_fields: []});
    setBroadcast(inputValue);
    field.onChange(e);
    if (values.n_type != 'Personalized Concur Open'){
      setSubject(SUBJECT_DICT[values.n_type] + moment(mwin_start_date).format('MMMM D, YYYY'))
    }
  }

  const onChangeTemplate = (e, values, field, setValues) => {
    const templateVal = e.target.value;
    if (templateVal == 'None'){
      setValues({...values,  selected_template: templateVal});
      return;
    }
    const vals = templates
    let template = null;
    vals.forEach(val => {
      if (val.pkey == templateVal){
        template = val;
      }
    })
    setValues({...values,  selected_template: template.pkey});
    setNotificationBody(template.body);
    field.onChange(e);
  }

  const handleNTypeChange = (e, setValues, values) => {
    setNType(e.target.value);
    if (e.target.value == 'Personalized Concur Open'){
      setValues({...values,  start_date: startDate});
      setValues({...values,  end_date: endDate});
      setSubject('');
    }
    else{
      delete values.start_date
      delete values.end_date
      setSubject(SUBJECT_DICT[e.target.value] + moment(mwin_start_date).format('MMMM D, YYYY'))
    }
    if (e.target.value == 'Maintenance Complete'){
      setSendTime(new Date(new Date(mwin_end_date).toLocaleString('en', {timeZone: 'America/Los_Angeles'})))
      setValues({...values,  send_time: sendTime});
    }
    else if (e.target.value == 'Maintenance Update' || e.target.value == 'Maintenance Cancelled' || e.target.value == 'Maintenance Announcement'){
      setValues({...values,  send_time: sendTime});
    }
    else{
      delete values.send_date
    }
  }



  return (
    <div >
      <AuthorizationWrapper requiredAction={editAction}>
        {/* Overlay Tooltip for button */}
        <OverlayTrigger
          key='left'
          placement='left'
          overlay=
          {
            <Tooltip id={`tooltip-left`}>Creates a new Notification</Tooltip>
          }
        >
          <Button className="data-cy-create-message-modal-button" variant="primary" onClick={openModal}>Create Notification</Button>
        </OverlayTrigger>
      </AuthorizationWrapper>
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-create-message-modal">
      <Formik   
          enableReinitialize
          validationSchema={schema}
          onSubmit={values => submitFunc(values)}
          initialValues={{
            is_test: 'N',
            is_internal: 'N',
            broadcast: broadcast,
            start_date: startDate,
            end_date: endDate,
            send_time: sendTime,
            title: title,
            flag: flag,
            mwin_id: mwin_id,
            body: notificationBody,
            n_type: nType,
            created_by: email,
            subject: subject,
            comm_src: 'Aviary Create Notification',
          }}
        >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldTouched,
          values,
          errors,
          touched,
          setValues,
        }) => 
        (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Create Notification</Modal.Title>
            </Modal.Header>
            {/* Confirmation Step Title */}
           
            <Modal.Body>
              {/* Main Step Body */}
              <div>
                {nType == 'Personalized Concur Open' && <Form.Group style={{marginBottom: '0px'}}>
                  <div style={{width: '40%', display: 'flex'}}>
                    <div style={{marginRight: '3vw'}}>
                      <Form.Label>Start Date</Form.Label>
                      <DatePicker
                          onChange            = {(date) => {
                            setStartDate(date); 
                            setFormattedStartDate(moment(new Date(date).toLocaleString('en', {timeZone: timezone})).format('MM/DD/YYYY, hh:mm A'));
                            if (date > endDate){
                              setEndDate(date); 
                              setFormattedEndDate(moment(new Date(date).toLocaleString('en', {timeZone: timezone})).format('MM/DD/YYYY, hh:mm A'));
                            }
                          }}
                          selected            = {startDate}
                          showTimeInput       = "true"
                          dateFormat          = "Pp"
                          style               = {{width: '100%'}}  
                          required
                      />
                    </div>
                    <div>
                      <Form.Label>End Date</Form.Label>
                      <DatePicker
                          onChange            = {(date) => {
                            setEndDate(date); 
                            setFormattedEndDate(moment(new Date(date).toLocaleString('en', {timeZone: timezone})).format('MM/DD/YYYY, hh:mm A'));
                          }}
                            
                          selected            = {endDate}
                          showTimeInput       = "true"
                          dateFormat          = "Pp"
                          style               = {{width: '100%'}}  
                          minDate             = {startDate}
                          required  
                      />
                    </div>      
                  </div>
                </Form.Group>  }
                {nType == 'Personalized Concur Open' && <Row style={{marginBottom: '1rem'}}>
                  <div style={{width: '40%', display: 'flex', fontSize: '14px'}}>{formattedStartDate}</div>
                  <div style={{width: '40%', display: 'flex', fontSize: '14px', marginLeft: '2.7vw'}}>{formattedEndDate}</div>
                </Row>}
               
                {nType == 'Personalized Concur Open' && <Form.Group style={{display: 'block', width: '40%'}}>   
                  <Form.Label>Preview Timezone</Form.Label>
                  <Form.Control 
                    as="select" 
                    name="timezone" 
                    value={timezone}  
                    onChange={(e) => {
                      //these dates come in as pst, but with the timezone denotion of your local time like -0500
                      //this converts that offset to pst, so it can then be converted into the desired timezone accurately
                      const adjustedEndDate = new Date(endDate.getTime() - offsetDif * MILISECOND_CONVERT)
                      const adjustedStartDate = new Date(startDate.getTime() - offsetDif * MILISECOND_CONVERT)
                      setFormattedEndDate(moment.tz(adjustedEndDate, e.target.value).format('MM/DD/YYYY, hh:mm A'))
                      setFormattedStartDate(moment.tz(adjustedStartDate, e.target.value).format('MM/DD/YYYY, hh:mm A'))
                      setTimezone(e.target.value);
                    }}>
                    <option value="Europe/Paris">{dstActive ? "CEST" : "CET"}</option>
                    <option value="Etc/Greenwich">GMT</option>
                    <option value="US/Central">{dstActive ? "CDT" : "CST"}</option>
                    <option value="America/Los_Angeles">{dstActive ? "PDT" : "PST"}</option>
                  </Form.Control>
                </Form.Group>}
                <Form.Group style={{display: 'flex'}}>      
                  <div style={{marginRight: '3vw'}}>
                    <Form.Label>Type</Form.Label>     
                    <Form.Control as="select" name="n_type" value={nType} onChange={e => handleNTypeChange(e, setValues, values)}>
                      <option value="Maintenance Announcement">Maintenance Announcement</option>
                      <option value="Maintenance Update">Maintenance Update</option>
                      <option value="Maintenance Complete">Maintenance Complete</option>
                      <option value="Maintenance Cancelled">Maintenance Cancelled</option>
                      <option value="Personalized Concur Open">Personalized Concur Open</option>
                    </Form.Control>
                    </div>
                </Form.Group>
                {nType != 'Personalized Concur Open' && values.broadcast == 'Y' && <Form.Group>
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type="textarea"
                    as="textarea"
                    className="data-cy-create-message-modal-title"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    onBlur={handleBlur}
                    name="subject"></Form.Control>
                    {errors.subject && touched.subject ? (
                      <div style={styles.error}>{errors.subject}</div>
                    ) : null}
                </Form.Group>}
                <Form.Group>
                  <Form.Label>Notification Title</Form.Label>
                  <Form.Control
                    type="textarea"
                    as="textarea"
                    className="data-cy-create-message-modal-title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    onBlur={handleBlur}
                    name="title"></Form.Control>
                    {errors.title && touched.title ? (
                      <div style={styles.error}>{errors.title}</div>
                    ) : null}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Insert Template Text</Form.Label>
                  <OverlayTrigger
                    key='right'
                    placement='right'
                    overlay=
                    {
                      <Tooltip id={`tooltip-right`}>
                        Inserts text into the Notification Body field. WARNING will delete Notification Body contents.
                      </Tooltip>
                    }>
                    <img src = {infoImage} style = {{ ...styles.infoImg, ...styles.inlineMiddleVAlign }} />
                  </OverlayTrigger>
                  <Field>
                    {({ field }) => (
                      <Form.Control as="select" name="template_text" value={values.selected_template} onBlur={handleBlur} onChange={e => onChangeTemplate(e, values, field, setValues)}>
                        <option key='None' value='None'></option>
                        { templates.map((type, i) => {
                          if (type['notification_type'] == values.n_type)return ( <option key={'template-' + i} value={type.pkey} id={type.body}>{type.title}</option>)
                          })}
                        
                      </Form.Control>
                    )}
                  </Field>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Notification Body</Form.Label>
                  <Form.Control
                    type="textarea"
                    as="textarea"
                    className="data-cy-create-message-modal-notification-body"
                    value={notificationBody}
                    rows={5}
                    onChange={(e) => setNotificationBody(e.target.value)}
                    onBlur={handleBlur}
                    name="body"></Form.Control>
                    {errors.body && touched.body ? (
                      <div style={styles.error}>{errors.body}</div>
                    ) : null}
                </Form.Group>
                {nType != 'Personalized Concur Open' && <Form.Group>
                    <Form.Label>Send Email?</Form.Label>
                    <OverlayTrigger
                    key='right'
                    placement='right'
                    overlay=
                    {
                      <Tooltip id={`tooltip-right`}>
                        If enabled, users will have the ability to send an email associated with this notification.
                      </Tooltip>
                    }>
                    <img src = {infoImage} style = {{ ...styles.infoImg, ...styles.inlineMiddleVAlign }} />
                  </OverlayTrigger>
                    <Field>
                      {({ field }) => (
                        <Form.Group as={Row} className="data-cy-create-message-modal-broadcast" name="broadcast" value={values.broadcast} onBlur={handleBlur} onChange={e => onChangeBroadcast(e, values, field, setValues)} >
                           <Form.Check
                            type="radio"
                            name="broadcast"
                            label={t('containers.incidentManagementCreateMessage.yes')}
                            value="Y"
                            className="data-cy-create-message-modal-broadcast-Y"
                            id="emailYes"
                            style={styles.marginRight}
                            checked={values.broadcast === 'Y'}
                            onChange={e =>  handleChange(e, setValues, values)}
                          />
                          <Form.Check
                            type="radio"
                            label={t('containers.incidentManagementCreateMessage.no')}
                            name="broadcast"
                            value="N"
                            className="data-cy-create-message-modal-broadcast-N"
                            id="emailNo"
                            checked={values.broadcast === 'N'}
                            onChange={handleChange}
                          />
                        </Form.Group>

                      )}
                    </Field>
                    {errors.broadcast && touched.broadcast ? (
                        <div style={styles.error}>{errors.broadcast}</div>
                      ) : null}
                  </Form.Group>}
                {values.broadcast === 'Y' && <Form.Group style={{display: 'block', width: '40%'}}>   
                  <Form.Label>Send Time (Pacific Time)</Form.Label>
                  <DatePicker
                      onChange            = {(date) => {
                        setSendTime(date);
                      }}
                      selected={sendTime}
                      showTimeInput       = "true"
                      dateFormat          = "Pp"
                      style               = {{width: '100%'}}  
                      required
                  />
                </Form.Group>}
              </div>
            </Modal.Body>
             
            
            <Modal.Footer>
              <div style={{display: 'contents'}}>
                <Button variant="secondary" onClick={closeModal} className="data-cy-create-message-modal-close" style={styles.buttonLeft}>Cancel</Button>
                <Button style={styles.buttonRight} className="data-cy-create-message-modal-ok" type="submit">Create</Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
        </Formik>

      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(CreateNotificationModal);