import React, {useEffect, useState}      from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import {
  actions as securityIncidentActions
} from '../../../ducks/securityIncidents/securityIncident.index';
import { Modal, Button, Tooltip, OverlayTrigger, Card, Row } from 'react-bootstrap';
import {parseHtmlFromDb} from '../SecurityIncidentHelpers';
/* ========= PACKAGE IMPORTS ========= */

const mapStateToProps = ({securityIncidentState}) => ({
  emailContents         : securityIncidentState.emailContents,
  emails                : securityIncidentState.emails,
  specificEmailsLoading : securityIncidentState.specificEmailsLoading,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setEmailContents                : securityIncidentActions.setEmailContents,
    requestSpecificEmails           : securityIncidentActions.requestSpecificEmails,
    setEmailPreviewLoading          : securityIncidentActions.setEmailPreviewLoading,
    requestSendEmails               : securityIncidentActions.requestSendEmails,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const styles = {
  'icon': {
    'fontSize': '35px', 
    'color': '#007bff'
  }
};

const PromoteModal = ({email, submitFunction, canPromote, submitData, setEmailPreviewLoading, emailContents, setEmailContents, companyId, body="", subject="", clear=true, disableButton=false}) => {
  const [modalIsOpen,setIsOpen] = useState(false);
  const { icon } = styles;
  useEffect(() => {
    if (body){
      setEmailContents(parseHtmlFromDb(null, body));
      setEmailPreviewLoading(false);
    }
  }, [modalIsOpen]);
  
  useEffect(() => {
    if (body){
      let stuff = parseHtmlFromDb(null, body);
      setEmailContents(stuff); 
    }
  }, [body]);
  
  useEffect(() => {
    if (email && email.body !== "" && emailContents.length === 0 && modalIsOpen){
      setEmailContents([]);
      setEmailContents(parseHtmlFromDb(null, email.body)); 
    }
  }, [email, modalIsOpen]);

  const openModal = () => {
    if (!disableButton){
      setIsOpen(true);
      if (clear){
        setEmailContents([]);
      }
    }
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const submitSend = () => {
    if (canPromote){
      submitFunction(submitData);
    }
    if (clear){
      setEmailContents([]);
    }
    closeModal();
  }

  return (
    <div style={{display: 'unset'}}>
       <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Review Email</Tooltip>
        }
      >
        <MdOutlineRemoveRedEye style={icon} onClick={openModal}/>
      </OverlayTrigger>
     
      
      <Modal size='lg' show={modalIsOpen} onHide={closeModal} className="data-cy-preview-email-modal">
      {<div style={{textAlign: 'center'}}>
          <Modal.Header closeButton>
            <Modal.Title>Review Email</Modal.Title>
          </Modal.Header>
          <Card>
            <Card.Body>
              <br/>
              {!subject && <h3>{email.subject}</h3>}
              {subject && <h3>{subject}</h3>}
              <Row style={{justifyContent: 'center'}}>
                <div>Company Id:&nbsp;&nbsp;</div>
                <div>{companyId}</div>
              </Row>
              <br/>
              {emailContents.map((element, index) => {
                return (
                  <span style={{display: 'flex', justifyContent: 'center'}} key={index} className="Container">
                    {element}
                  </span>
                )})
              }
            </Card.Body>
          </Card>
          <div style={{display: 'flex'}}>
            <Button variant="secondary" onClick={closeModal} className="data-cy-preview-email-modal-close" style={{margin: '1vw', width: '8rem', marginRight: 'auto'}}>Cancel</Button>
            {canPromote && <Button variant="primary" onClick={() => submitSend()} style={{float: 'right', width: '8rem', margin: '1vw'}}>Promote</Button>}
          </div>
        </div>}
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(PromoteModal);