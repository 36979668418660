/* ========= PACKAGE IMPORTS ========= */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import CreateTemplateModal from './CreateTemplateModal';
import SearchTable from '../Tables/SearchTable';
import UpdateTemplateModal from './UpdateTemplateModal';
import DeleteTemplateModal from './DeleteTemplateModal';
/* ========= REDUX IMPORTS ========= */
import { actions as uiActions }    from '../../ducks/ui/ui.index';
import {
  actions as templateActions,
}   from '../../ducks/template/template.index';


/* ========= COMPONENT IMPORTS ========= */
import Loading            from '../../components/loading';
import AuthorizationWrapper from '../../components/authorizationWrapper';


/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({uiState, templateState}, props) => {
  let type;
  type = props.match.params['type'];
  return {
    pageLoading            :  uiState.pageLoading,
    message_templates      :  templateState.message_templates,
    notification_templates :  templateState.notification_templates,
    type                   :  type
  }
};

const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    setPageLoading   :  uiActions.setPageLoading,
    requestTemplates :  templateActions.requestTemplates
  };
  return bindActionCreators(combinedActionCreators, dispatch);
};

const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
  },
  'pageTitle': {
    'marginBottom' :  '20px',
    'width'        :  '73vw',
    'textAlign'    :  'center',
  },
};

const requiredAction = 'edit_template';
const createTemplateAction = "create_template"
const updateTemplateAction = "edit_template"
const deleteTemplateAction = "delete_template"
const TemplateManagement = ({pageLoading, message_templates, notification_templates, type}) => {
  const { topContainer, pageTitle, headerStyle } = styles;
  let types = [];
  if (type == 'message' && message_templates){
    types = Object.keys(message_templates)
  }
  else if (type == 'notification' && notification_templates){
    types = Object.keys(notification_templates)
  }
  const notification_columns = React.useMemo(
    () => [
        {
          Header: <b data-cy='message-type-header'>Template Type</b>,
          headerStyle: headerStyle,
          id: 'template_type',
          width: 150,
          accessor: (data) => data.template_type,
          filter: 'includes'
        },
        {
          Header: <b data-cy='message-type-header'>Notification Type</b>,
          headerStyle: headerStyle,
          id: 'message_type',
          width: 220,
          accessor: (data) => data.notification_type,
          filter: 'includes'
        },
        {
          Header: <b data-cy='title-header'>Title</b>,
          headerStyle: headerStyle,
          id: 'title',
          width: 220,
          accessor: (data) => data.title,
          filter: 'includes'
        },  
        {
          Header: <b data-cy='body-header'>Body</b>,
          headerStyle: headerStyle,
          id: 'notification_body',
          width: 500,
          accessor: (data) => data.body,
          filter: 'includes'
        },

        {
          Header      : <b data-cy='edit-header'></b>,
          headerStyle : headerStyle,
          id          : 'edit',
          width       : 120,
          accessor    : (data) => <AuthorizationWrapper requiredAction={updateTemplateAction}>
                                    <UpdateTemplateModal template_type={type} notificationType={data.notification_type} pkey={data.pkey} title={data.title} body={data.body} messageType={data.message_type} affectedService={data.affected_service}/>
                                  </AuthorizationWrapper>
        },
        {
          Header: <b data-cy='delete-header'></b>,
          headerStyle: headerStyle,
          id: 'delete',
          width: 50,
          accessor: (data) =>  <AuthorizationWrapper requiredAction={deleteTemplateAction}>
                                  <DeleteTemplateModal templateId={data.pkey} title={data.title} body={data.body} messageType={data.message_type} affectedService={data.affected_service}/>
                                </AuthorizationWrapper>
        }
      ],
  [])
  const message_columns = React.useMemo(
    () => [
        {
          Header: <b data-cy='message-type-header'>Template Type</b>,
          headerStyle: headerStyle,
          id: 'template_type',
          width: 150,
          accessor: (data) => data.template_type,
          filter: 'includes'
        },
        {
          Header: <b data-cy='message-type-header'>Message Type</b>,
          headerStyle: headerStyle,
          id: 'message_type',
          width: 220,
          accessor: (data) => data.message_type,
          filter: 'includes'
        },
        {
          Header: <b data-cy='affected-service-header'>Affected Service</b>,
          headerStyle: headerStyle,
          id: 'affected_service',
          width: 220,
          accessor: (data) => data.affected_service,
          filter: 'includes'
        },
        {
          Header: <b data-cy='title-header'>Title</b>,
          headerStyle: headerStyle,
          id: 'title',
          width: 220,
          accessor: (data) => data.title,
          filter: 'includes'
        },  
        {
          Header: <b data-cy='body-header'>Body</b>,
          headerStyle: headerStyle,
          id: 'notification_body',
          width: 500,
          accessor: (data) => data.body,
          filter: 'includes'
        },

        {
          Header      : <b data-cy='edit-header'></b>,
          headerStyle : headerStyle,
          id          : 'edit',
          width       : 120,
          accessor    : (data) => <AuthorizationWrapper requiredAction={updateTemplateAction}>
                                    <UpdateTemplateModal pkey={data.pkey} notificationType={data.notification_type} template_type={type} title={data.title} body={data.body} messageType={data.message_type} affectedService={data.affected_service}/>
                                  </AuthorizationWrapper>
        },
        {
          Header: <b data-cy='delete-header'></b>,
          headerStyle: headerStyle,
          id: 'delete',
          width: 50,
          accessor: (data) =>  <AuthorizationWrapper requiredAction={deleteTemplateAction}>
                                  <DeleteTemplateModal templateId={data.pkey} title={data.title} body={data.body} messageType={data.message_type} affectedService={data.affected_service}/>
                                </AuthorizationWrapper>
        }
      ],
  [])
  return (
    <AuthorizationWrapper requiredAction={requiredAction}>
      <div style={topContainer}>
        {/* HEADER */}
        <h1 style={pageTitle}>Templates</h1>
        <div style={{'display': 'grid', 'justifyContent': 'end'}}>
          <AuthorizationWrapper requiredAction={createTemplateAction}><CreateTemplateModal template_type={type}></CreateTemplateModal></AuthorizationWrapper>
        </div>
        <div>
          { /* CONTENT */
            pageLoading || (type == 'message' && !message_templates) || (type == 'notification' && !notification_templates)
            ? <Loading height="75vh" />
            : <SearchTable data={ type == 'message' ? message_templates : notification_templates} columns={ type == 'message' ? message_columns : notification_columns} template_type={type}/>
              
          }
        </div>
      </div>
    </AuthorizationWrapper>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateManagement);