import moment from "moment";

/* ===== STATE_DEFINITION ===== */
export const defaultFilters = {
  date_range_start       : moment().subtract(7, 'days').toDate(),
  date_range_end         : moment().toDate(),
  sort_by                : undefined,
  sort_direction         : 'DESC',
  results_per_page       : 100,
  page_number            : 1,
  keywords               : '',
  selected_data_centers  : [],
  selected_services      : [],
  selected_cis           : [],
  change_type            : 'manual',
  relative_time_number   : 7,
  relative_time_unit     : 'days',
  relative_time_exceeded : false,
  absolute_selected      : false,
  record_display_style   : 'card',
  search_type            : 'any',
};
defaultFilters["change_type_options"] = [
  { systemKey: 'manual', displayVal: 'Manual', selected: (defaultFilters['change_type'] === 'manual') },
  { systemKey: 'auto',   displayVal: 'Auto',   selected: (defaultFilters['change_type'] === 'auto') },
];
defaultFilters["search_type_options"] = [
  { systemKey: 'any', displayVal: 'Any', visible: true, selected: true},
  { systemKey: 'master_approval_entity_id',   displayVal: 'OPI', visible: true, selected: false },
  { systemKey: 'company_id',   displayVal: 'Company ID', visible: true, selected: false },
  { systemKey: 'description',   displayVal: 'Description', visible: true, selected: false },
  { systemKey: 'summary',   displayVal: 'Summary', visible: true, selected: false },
  { systemKey: 'submitted_by',   displayVal: 'Owned By', visible: true, selected: false },
  { systemKey: 'catalog_type',   displayVal: 'Catalog Type', visible: (defaultFilters['change_type'] === 'auto'), selected: false },
];
defaultFilters["relative_time_unit_options"] = [
  { systemKey: 'minutes', displayValSingular: 'Minute', displayValPlural: 'Minutes', selected: (defaultFilters['relative_time_unit'] === 'minutes') },
  { systemKey: 'hours', displayValSingular: 'Hour', displayValPlural: 'Hours', selected: (defaultFilters['relative_time_unit'] === 'hours') },
  { systemKey: 'days', displayValSingular: 'Day', displayValPlural: 'Days', selected: (defaultFilters['relative_time_unit'] === 'days') },
  { systemKey: 'weeks', displayValSingular: 'Week', displayValPlural: 'Weeks', selected: (defaultFilters['relative_time_unit'] === 'weeks') },
  { systemKey: 'months', displayValSingular: 'Month', displayValPlural: 'Months', selected: (defaultFilters['relative_time_unit'] === 'months') },
];

export const initialState = {
  // searchable multi-selects - changes to these fields will make requests search change entity
  loading_data_centers        :  false,
  entity_results_data_centers :  [],

  loading_services        :  '',
  entity_results_services :  [],

  loading_cis        :  '',
  entity_results_cis :  [],

  // Checks to see if new page should be rendered based on if API response is given
  pageLoading                : true,
  apiResultsLoaded           : false,

  // for Search Changes response
  //Comments on structure
  searchChangeResponse : [],
  searchChangeLoading: false,

  maxMonthsDateRange : 3,
  error: false,
  errorMessage: "",


  // Set the default state to contain the default filter values
  ...defaultFilters
};



/* ===== TYPES ===== */
export const types = {
  SUBMIT_ENTITY_SEARCH              : '[change_history] SUBMIT_ENTITY_SEARCH',
  SET_SEARCH_CHANGE_ENTITY          : '[change_history] SET_SEARCH_CHANGE_ENTITY',
  SUBMIT_SEARCH_CHANGES             : '[change_history] SUBMIT_SEARCH_CHANGES',
  RESET_FILTERS                     : '[change_history] RESET_FILTERS',
  SET_SEARCH_CHANGES                : '[change_history] SET_SEARCH_CHANGES',
  SET_STATE_FIELD                   : '[change_history] SET_STATE_FIELD',
  SET_CHANGE_TYPE_OPTIONS           : '[change_history] SET_CHANGE_TYPE_OPTIONS',
  SET_SEARCH_TYPE_OPTIONS           : '[change_history] SET_SEARCH_TYPE_OPTIONS',
  SET_RELATIVE_TIME_UNIT_OPTIONS    : '[change_history] SET_RELATIVE_TIME_UNIT_OPTIONS',
  SUBMIT_SEARCH_CHANGE_ENTITY       : '[change_history] SUBMIT_CHANGE_ENTITY',
  SET_PAGE_LOADING                  : '[change_history] SET_PAGE_LOADING',
  SET_CHANGE_HISTORY_ERROR          : '[change_history] SET_CHANGE_HISTORY_ERROR',
  SET_CHANGE_HISTORY_ERROR_MESSAGE  : '[change_history] SET_CHANGE_HISTORY_ERROR_MESSAGE',
  PUT_CHANGE_HISTORY_SETTINGS       : '[change_history] PUT_CHANGE_HISTORY_SETTINGS'
};


/* ===== ACTION_CREATORS ===== */
export const actions = {
  submitEntitySearch: (entity, searchText, limit=100) => ({
    type    :  types.SUBMIT_ENTITY_SEARCH,
    payload :  {
      "entity"      : entity,
      "search_text" : searchText,
      "limit"       : limit,
    }
  }),
  setSearchChangeEntity: (res, entity) => {
    return {
      type    :  types.SET_SEARCH_CHANGE_ENTITY,
      payload :  {
        results      :  res,
        storeEntity  :  entity,
      }
    }
  },
  submitSearchChanges: ({page_number=1, sort_direction='DESC'} = {}) => ({
      type    :  types.SUBMIT_SEARCH_CHANGES,
      payload : { page_number, sort_direction }
    }),
  setSearchChanges: (res, sort_direction, page_number, apiResultsLoaded) => {
    return {
      type    : types.SET_SEARCH_CHANGES,
      payload : {
        results    : res,
        sort_direction,
        page_number,
        apiResultsLoaded
      }
    }
  },
  setStateField: (field, val) => ({
    type    :  types.SET_STATE_FIELD,
    payload :  { field, val },
  }),
  resetFilters: () => ({
    type    :  types.RESET_FILTERS,
    payload :  {},
  }),
  setChangeTypeOptions: (val) => ({ type: types.SET_CHANGE_TYPE_OPTIONS, payload: val }),
  setSearchTypeOptions: (val) => ({ type: types.SET_SEARCH_TYPE_OPTIONS, payload: val }),
  setRelativeTimeUnitOptions: (val) => ({ type: types.SET_RELATIVE_TIME_UNIT_OPTIONS, payload: val }),
  setPageLoading:(isPageLoading) => {
    return {
      type  : types.SET_PAGE_LOADING,
      payload: {
        pageLoading : isPageLoading
      }
    }
  },
  putChangeHistorySettings : (val) => ({ type: types.PUT_CHANGE_HISTORY_SETTINGS, payload: val}),
  setChangeHistoryError : (bool) => ({type: types.SET_CHANGE_HISTORY_ERROR, payload: bool}),
  setChangeHistoryErrorMessage : (val) => ({type: types.SET_CHANGE_HISTORY_ERROR_MESSAGE, payload: val}),
};


/* ===== SELECTORS ===== */
export const selectors = { };


/* ===== REDUCER ===== */
export default function reducer(state=initialState, action) {
  switch (action.type) {
    case types.SET_SEARCH_CHANGE_ENTITY: {
      const { results, storeEntity } = action.payload;
      return { ...state, [storeEntity]: results }
    }
    case types.SET_SEARCH_CHANGES: {
      return { ...state,
        searchChangeResponse: action.payload.results,
        sort_direction: action.payload.sort_direction,
        page_number:    action.payload.page_number,
        apiResultsLoaded: action.payload.apiResultsLoaded
       }
    }
    case types.SET_PAGE_LOADING: {
      return {...state, pageLoading: action.payload.pageLoading}
    }

    case types.SET_STATE_FIELD: {
      const {field, val} = action.payload;
      return { ...state, [field] : val };
    }

    case types.RESET_FILTERS: {
      return { ...state, ...defaultFilters };
    }

    case types.SET_CHANGE_TYPE_OPTIONS: {
      const updatedSelectedValue =  state.change_type_options.map( (option) =>
        ({ ...option, selected: option.displayVal === action.payload ? true : false }));
      let selected_search_type = state.search_type;
      const updatedSearchTypeValue =  state.search_type_options.map( (option) => {
        let optionVisible = true;
        let optionSelected = option.selected;
        //don't show catalog type if change type is set to manual
        //the logic here gets gross here
        if (action.payload === 'Manual'){
          if (option.displayVal === 'Catalog Type'){
            optionVisible = false;
            //if catalog_type selected in dropdown and manual being selected, catalog_type must dissapear
            //this unselects it. The else if selects any by default in this edge case.
            //selected_search_type sets the search_type in the state in this case.
            if (state.search_type === 'catalog_type'){
              optionSelected = false;
              selected_search_type = 'any';
            }
          }
          //select any if manual and catalog_type is selected 
          else if (option.displayVal === 'Any' && state.search_type === 'catalog_type'){
            optionSelected = true;
          }
        }
        return { ...option, visible: optionVisible, selected: optionSelected }
      });
      return { ...state, change_type_options: updatedSelectedValue, search_type_options: updatedSearchTypeValue, search_type: selected_search_type };
    }

    case types.SET_RELATIVE_TIME_UNIT_OPTIONS: {
      const updatedSelectedValue =  state.relative_time_unit_options.map( (option) =>
        ({ ...option, selected: option.systemKey === action.payload ? true : false }));

      return { ...state, relative_time_unit_options: updatedSelectedValue };
    }

    case types.SET_SEARCH_TYPE_OPTIONS: {
      let setType = '';
      const updatedSelectedValue =  state.search_type_options.map( (option) =>{
        if (option.displayVal === action.payload){
          setType = option.systemKey;
        }
        return { ...option, selected: option.displayVal === action.payload ? true : false }
      });
      return { ...state, search_type_options: updatedSelectedValue, search_type: setType };
    }

    case types.SET_CHANGE_HISTORY_ERROR: {
      return { ...state, error : action.payload };
    }

    case types.SET_CHANGE_HISTORY_ERROR_MESSAGE: {
      return { ...state, errorMessage : action.payload };
    }

    default: { return state; }
  }
}
