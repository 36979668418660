import http from "../../services/httpService";

let apiEndpoint = '/incidents';


export const getAllActiveIncidents = async (locale, start_at, response_size) => {
  const result = await http.get(`${apiEndpoint}/?active=true&locale=${locale}&start_at=${start_at}&response_size=${response_size}`);

  return result;
};

export const getAllHistoricIncidents = async (locale, start_at, response_size) => {
  const result = await http.get(`${apiEndpoint}/?active=false&locale=${locale}&start_at=${start_at}&response_size=${response_size}`);

  return result;
};


export const getIncidentDetails = async (id) => {
  const result = await http.get(`${apiEndpoint}/incident_team_data/${id}/`);
  return result;
}

export const getAffectedCompanies = async (id, start_at, response_size) => {
  let url = `${apiEndpoint}/affected_companies/?jira_id=${id}&start_at=${start_at}&response_size=${response_size}`
  const result = await http.get(url);
  return result;
}

export const getIncidents = async (payload) => {
  return await http.get(`priority_incidents?priority_type=${payload.incidentType}&response_size=${payload.response_size}&start_at=${payload.start_at}&sort_col=${payload.sort_col}&sort_direction=${payload.sort_direction}`);
};

export const getIncidentByJiraId = async (id, locale) => await http.get(`/incident_by_jira_id/?jira_id=${id}&locale=${locale}`);

export const getEmailFields = async () => {
  return await http.get(`/get_email_components`);
};

export const createMessage = async (payload) => await http.post('/create_message/', payload);
export const updateMessage = async (payload) => await http.put('/update_message/', payload);
export const deleteMessage = async (payload) => await http.delete('/delete_message/', { data: payload});
export const updateMessageBody = async (payload) => await http.put('/update_message_body/', payload);
export const getIncidentMessages = async (id) => {
  const result = await http.get(`/get_messages/?jira_id=${id}`)
  return result;
};

export const getP2AlertTypes = async () => {
  const result = await http.get(`/v4/get_p2_alert_types`)
  return result;
};

export const updateBanner = async(payload) => await http.post('/banner', payload);
export const getBannerState = async () => await http.get('/banner');
