import React, {useEffect, useState}      from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Button, Row } from 'react-bootstrap';
import { Link }     from 'react-router-dom';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import { actions as securityIncidentActions } from '../../ducks/securityIncidents/securityIncident.index';
import Loading            from '../../components/loading';
import SortTable from '../Tables/SortTable';
import { MdOutlineMode } from "react-icons/md";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import './breadcrumbs.css'
import {
  dateTimeToUTCString,
  sortOpi,
  newSortDate,
} from '../../utils/formatFunctions'
import PreviewSendEmailModal from './Modals/PreviewSendEmailModal';
import DeleteModalTemplate from './Modals/DeleteModalTemplate';

const mapStateToProps = ({securityIncidentState}, props) => {
  let commId;
  commId = props.match.params['id'];
  return {
    commId                : commId,
    emailsLoading         : securityIncidentState.emailsLoading,
    commsLoading          : securityIncidentState.commsLoading,
    commTypesLoading      : securityIncidentState.commTypesLoading,
    securityUsersLoading  : securityIncidentState.securityUsersLoading,
    emails                : securityIncidentState.emails,
    comms                 : securityIncidentState.comms,
    commTypes             : securityIncidentState.commTypes,
    securityUsers         : securityIncidentState.securityUsers,
    securityUsersImpact   : securityIncidentState.securityUsersImpact,
  }
};

const mapDispatchToProps = (dispatch) => {
const combinedActionCreators = {
  requestEmails               : securityIncidentActions.requestEmails,
  requestComms                : securityIncidentActions.requestComms,
  requestCommTypes            : securityIncidentActions.requestCommTypes,
  setSpecificEmails           : securityIncidentActions.setSpecificEmails,
  requestDeleteEmails         : securityIncidentActions.requestDeleteEmails,
  requestSecurityUsersImpact  : securityIncidentActions.requestSecurityUsersImpact,
  setSecurityUsersLoading     : securityIncidentActions.setSecurityUsersLoading,
  setEmails                   : securityIncidentActions.setEmails,
  setSecurityUsersImpact  : securityIncidentActions.setSecurityUsersImpact,
};
return bindActionCreators(combinedActionCreators, dispatch);
};
const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
    'minWidth' :  '73vw',
  },
  'pageTitle': {
    'marginLeft'   :  'auto',
    'marginRight'  :  'auto',
    'textAlign'    :  'center',
    'fontSize'     :  '2rem',
  },
};

const requiredAction = 'view_emails';

const Emails = ({requestEmails, requestSecurityUsersImpact, setEmails, setSecurityUsersLoading, setSecurityUsersImpact, securityUsersImpact, requestComms, emailsLoading, securityUsersLoading, commsLoading, commId, emails, securityUsers, comms, setSpecificEmails, requestDeleteEmails}) => {
  const { topContainer, pageTitle, headers } = styles;
  const [secId, setSecId] = useState('');

  useEffect(() => {
    setEmails([]);
    setSecurityUsersImpact({});
    requestComms();
    requestEmails(commId);
  }, []);

  useEffect(() => {
    if (!emailsLoading && Object.keys(securityUsersImpact).length == 0){
      if (emails.length == 0){
        setSecurityUsersLoading(false);
        return;
      }
      let uniqueCompanyIds = [];
      emails.forEach((email) => {
        if (!uniqueCompanyIds.includes(email.company_id)){
          uniqueCompanyIds.push(email.company_id);
        }
      });
      requestSecurityUsersImpact(uniqueCompanyIds);
    }
  }, [emails, emailsLoading]);

  useEffect(() => {
    if (comms.length > 0){
      let intCommId = parseInt(commId);
      comms.forEach((comm) => {
        if (comm.sec_comm_id === intCommId){
          setSecId(comm.sec_id);
        }
      });
    }
  }, [comms]);

  const sort = {
    id: "id",
    desc: true
  }

  const columns = React.useMemo(
    () => [
        {
          Header: <b style={styles.test} data-cy='id-header'>ID</b>,
          headerStyle: headers,
          id: 'id',
          size: '50px',
          Cell          : ({value}) => <div style={{width: '50px'}}>{value}</div>,
          accessor      : "id",
        },
        {
          Header: <b style={styles.test} data-cy='id-header'>Company ID</b>,
          headerStyle: headers,
          id: 'company_id',
          size: '50px',
          Cell          : ({value}) => <div style={{width: '50px'}}>{value}</div>,
          accessor      : "company_id",
          sortMethod    : (a, b) => sortOpi(a, b),
        },
        {
          Header: <b style={styles.test} data-cy='id-header'>Recipient Count</b>,
          headerStyle: headers,
          id: 'recipient',
          size: '50px',
          accessor    : data => <div style={{width: '100px'}}>{securityUsersImpact[data.company_id] ?? 'None'}</div>,
        },
        {
          Header      : <b data-cy="internal-header">Subject</b>,
          headerStyle : headers,
          id          : 'subject',
          size       : '100px',
          accessor    : data => <div style={{width: '100px'}}>{data.subject}</div>,
        },
        {
          Header: <b data-cy='data-center-header'>Body</b>,
          headerStyle: headers,
          id: 'body',
          width: 30,
          accessor: (data) => <PreviewSendEmailModal id={data.id} email={data} companyId={data.company_id} isButton={false} previewOnly={true}/>
        },
        {
          Header: <b data-cy="created-date-header">Sent Date</b> ,
          headerStyle: headers,
          id: 'sent_date',
          accessor: 'sent_date',
          size: '230px',
          Cell      : ({value}) => (<div style={{width: '100px'}}>{dateTimeToUTCString(value)}</div>),
          sortType: newSortDate,
        },
        {
          Header      : <b data-cy="internal-header">Upload Source</b>,
          headerStyle : headers,
          id          : 'upload_source',
          size       : '100px',
          accessor    : data => <div style={{width: '100px'}}>{data.upload_source}</div>,
        },
        {
          Header: <b data-cy='data-center-header'>Preview/Send</b>,
          headerStyle: headers,
          id: 'sec_comm_id1',
          width: 30,
          accessor: (data) => <PreviewSendEmailModal id={data.id} disableButton={data.was_sent !== 'N' || securityUsersImpact[data.company_id] == undefined} email={data} companyId={data.company_id} isButton={false} previewOnly={false}/>
        },
        {
          Header: <b data-cy='data-center-header'>Edit</b>,
          headerStyle: headers,
          id: 'sec_comm_id2',
          width: 50,
          accessor: (data) => <Link to={`/compose_email/${data.sec_comm_id}/${data.company_id}/${data.id}`} style={{marginLeft: '10px'}} ><MdOutlineMode style={{fontSize: "35px"}}/></Link>
        },
        {
          Header: <b data-cy='data-center-header'>Delete</b>,
          headerStyle: headers,
          id: 'sec_comm_id3',
          width: 50,
          accessor: (data) => <DeleteModalTemplate 
          deleteFunction={requestDeleteEmails}
          delete_id={data.id} 
          data={data}
          type="comm content"
          html_modal={true} />
        },
      ]
    )
    const widthDict = {
      'id': 122,
      'title': 260,
      'description': 300,
      'start_date': 230,
      'actions': 230
    }

return (
  <AuthorizationWrapper requiredAction={requiredAction}>
    <div >
      {
        emailsLoading || commsLoading || securityUsersLoading
        ? <div>
            <div style={pageTitle}>Loading...</div>
            <Loading height="75vh" />
          </div>
        : <div style={topContainer}>
            <Row>
              <div style={pageTitle}>Emails</div>
            </Row>
            <Row>
              <Breadcrumb style={{marginTop: 'auto'}}>
                <Breadcrumb.Item href="/security_incidents">Security Incidents</Breadcrumb.Item>
                <Breadcrumb.Item href={"/comms/" + secId}>Comms</Breadcrumb.Item>
                <Breadcrumb.Item active>Emails</Breadcrumb.Item>
              </Breadcrumb>
              {<Link to={`/mass_import/${secId}/${commId}`} style={{marginLeft: 'auto'}}><Button>Mail Merge Template Upload</Button></Link>}
              {<Link to={`/compose_email/${commId}`} style={{marginLeft: '1rem'}}><Button>Create Email</Button></Link>}
            </Row>
            <SortTable data={emails} columns={columns} defaultSort={sort} setSpecificEmails={setSpecificEmails} widthDict={widthDict}/>
          </div>
      }
    </div>


  </AuthorizationWrapper>
)
}

export default connect(mapStateToProps, mapDispatchToProps)(Emails);