/* ========= PACKAGE IMPORTS ========= */
import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Button, Row } from 'react-bootstrap';
import { MultiSelect } from 'primereact/multiselect';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
/* ========= REDUX IMPORTS ========= */
import { actions as auditActions } from '../../ducks/audit/audit.index';
import SearchTable from './../Tables/SearchTable';
/* ========= COMPONENT IMPORTS ========= */
import Loading            from '../../components/loading';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import {
  dateTimeToUTCString,
  sortOpi,
  newSortDate,
} from '../../utils/formatFunctions'

/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({ auditState }) => ({
    audits : auditState.audits,
    auditOptions : auditState.auditOptions,
    auditLoading : auditState.auditLoading,
    auditOptionsLoading : auditState.auditOptionsLoading,
    moreResults : auditState.moreResults,
});

const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    getAudits  : auditActions.getAudits,
    getAuditOptions: auditActions.getAuditOptions,
    setAuditLoading : auditActions.setAuditLoading,
    setAudits: auditActions.setAudits,
  };
  return bindActionCreators(combinedActionCreators, dispatch);
};
const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
    'minWidth' : '75vw',
  },
  'headers': {
    'textAlign'    : 'left',
    'lineHeight'   : '2rem',
  },
  'pageTitle': {
    'textAlign'    :  'center',
    'marginLeft'   :  'auto',
    'marginRight'  :  'auto',
    'fontSize'     :  '2rem',
  },
  'evenRow':{
    'backgroundColor': '#007bff2e'
  },
  'tableContainer': {
    'fontSize'        :  '.94rem',
    'boxSizing'       :  'border-box',
    'borderRadius'    :  '4px',
    'backgroundColor' :  'rgb(250, 250, 250)',
    'boxShadow'       :  'rgba(0, 0, 0, 0.2) 0px 0px 10px -2px',
  },
  'noData': {
    'textAlign': 'center',
  }
};

let start = new Date();
start.setDate(start.getDate() - 14);
const requiredAction = 'view_aviary_audit';
const RESPONSE_SIZE = 100;

const Audit = ({auditLoading, auditOptionsLoading, audits, auditOptions, getAudits, getAuditOptions, moreResults}) => {
  const {pageTitle, headers, topContainer} = styles;
  const [searchValue, setSearchValue] = React.useState('');
  const [filter, setFilter] = React.useState('Action');

  const [emailOptions, setEmailOptions] = React.useState([]);
  const [actionOptions, setActionOptions] = React.useState([]);
  const [applicationOptions, setApplicationOptions] = React.useState([]);

  const [applicationName, setApplicationName] = React.useState([]);
  const [userEmail, setUserEmail] = React.useState([]);
  const [actionName, setActionName] = React.useState([]);
  const [sortBy, setSortBy] = React.useState('DESC');
  const [startDate, setStartDate] = React.useState(start);
  const [endDate, setEndDate] = React.useState(new Date());
  const [startAt, setStartAt] = React.useState(0);
  const [pageIndex, setPageIndex] = React.useState(0);

  React.useEffect(() => {
    getAuditOptions();
  }, []);

  React.useEffect(() => {
    if (Object.keys(auditOptions).length !== 0){
      setEmailOptions(auditOptions.users);
      setUserEmail(auditOptions.users);
      setActionOptions(auditOptions.actions);
      setActionName(auditOptions.actions);
      setApplicationOptions(auditOptions.applications);
      setApplicationName(auditOptions.applications);
    }
  }, [auditOptions, auditOptionsLoading]);

  React.useEffect(() => {
    //the useState set functions above have delay to them, so this fires the search after the state is set
    if (!auditOptionsLoading){
      callSearch();
    }
  }, [userEmail, actionName, applicationName, startDate, endDate, startAt, auditOptionsLoading]);

  const columns = React.useMemo(
    () => [
        {
          Header: <b style={styles.test} data-cy='id-header'>ID</b>,
          headerStyle: headers,
          id: 'pkey',
          size: '122px',
          Cell          : ({value}) => <div>{value}</div>,
          accessor      : "pkey",
          sortMethod    : (a, b) => sortOpi(a, b),
        },
        {
          Header      : <b data-cy="internal-header">Application</b>,
          headerStyle : headers,
          id          : 'application_name',
          size       : '100px',
          accessor    : data => <div style={{width: '100px'}}>{data.application_name}</div>,
        },
        {
          Header: <b data-cy='service-header'>Email</b>,
          headerStyle: headers,
          id: 'user_email',
          accessor    : data => <div style={{width: '100px'}}>{data.user_email}</div>,
          size: '260px',
        },
        {
          Header: <b data-cy="start-date-header">Action</b> ,
          headerStyle: headers,
          id: 'action_name',
          accessor: 'action_name',
          size: '230px',
          Cell      : ({value}) => <div>{value}</div>
        },
        {
          Header: <b data-cy="start-date-header">Change Date</b> ,
          headerStyle: headers,
          id: 'created_date',
          accessor: 'created_date',
          size: '230px',
          Cell      : ({value}) => (<div style={{width: '230px'}}>{dateTimeToUTCString(value)}</div>),
          sortType    :  newSortDate
        },
      ]
    )

    const sort = {
      id: "created_date",
      desc: sortBy == 'DESC' ? true : false
    }
    const widthDict = {
      'pkey': 122,
      'application_name': 260,
      'user_email': 300,
      'action_name': 230,
      'created_date': 230
    }

    const callSearch = () => {
      getAudits(applicationName, userEmail, actionName, '', '', sortBy, startAt, RESPONSE_SIZE);
    }


    const nextPage = () => {
      setStartAt(startAt + RESPONSE_SIZE);
      setPageIndex(pageIndex + 1);
    }
    const previousPage = () => {  
      setStartAt(startAt - RESPONSE_SIZE);
      setPageIndex(pageIndex - 1);
    }

    return (
      <AuthorizationWrapper requiredAction={requiredAction}>
        <div style={topContainer}>
          {
            <div>
              <Row>
                <div style={pageTitle}>Action Audit</div>
              </Row>
              <Row style={{marginTop: '1rem'}}>
               
                <div style={{display: 'flex', marginLeft: 'auto', textAlign: 'end', fontSize: '1.1rem', borderRadius: '25px'}}>
                  <div style={{width: 'fit-content', marginTop: 'auto', whiteSpace: 'nowrap', fontSize: '1.2rem', marginBottom: '-1px'}}>Start Date:&nbsp;</div>
                  <DatePicker
                    onChange            = {(date) => {
                      setStartDate(date); }}
                    selected            = {startDate}
                    showTimeInput       = "true"
                    dateFormat          = "Pp"
                    style               = {{fontSize: '1.2rem', borderRadius: '25px'}}  
                    required
                  />
                  <div style={{width: 'fit-content', marginTop: 'auto', whiteSpace: 'nowrap', marginLeft: '1rem', fontSize: '1.2rem', marginBottom: '-1px'}}>End Date:&nbsp;</div>
                  <DatePicker
                    onChange            = {(date) => {
                      setEndDate(date); }}
                    selected            = {endDate}
                    showTimeInput       = "true"
                    dateFormat          = "Pp"
                    style               = {{width: '100%'}}  
                    minDate             = {startDate}
                    required
                  />
                </div>
              </Row>
              {auditOptionsLoading && <Row style={{marginTop: '1rem'}}>
                <MultiSelect style={{width: '25vw'}} loading placeholder="Loading..." className="w-full md:w-20rem" />
                <MultiSelect style={{width: '25vw'}} loading placeholder="Loading..." className="w-full md:w-20rem" />
                <MultiSelect style={{width: '25vw'}} loading placeholder="Loading..." className="w-full md:w-20rem" />
                
              </Row>}
              {!auditOptionsLoading && <Row style={{marginTop: '1rem'}}>
                <MultiSelect style={{width: '25vw'}} value={applicationName} onChange={(e) => setApplicationName(e.value)} options={applicationOptions} display="chip"
                  placeholder="Select Application" className="w-full md:w-20rem" />
                <MultiSelect style={{width: '25vw'}} value={userEmail} onChange={(e) => setUserEmail(e.value)} options={emailOptions} display="chip"
                  placeholder="Select Emails" className="w-full md:w-20rem" />
                <MultiSelect style={{width: '25vw'}} value={actionName} onChange={(e) => setActionName(e.value)} options={actionOptions} display="chip"
                  placeholder="Select Actions" className="w-full md:w-20rem" />
              </Row>}
              {auditLoading
                ? <div>
                <div style={pageTitle}>Loading...</div>
                <Loading height="75vh" />
              </div>
          : <SearchTable data={audits} columns={columns} defaultSort={sort} widthDict={widthDict} sortCallback={setSortBy} sortDirection={sortBy}/> }
              <div style={{display: 'flex', textAlign: 'center', paddingBottom: '1rem'}}>
                <Col md={5} >
                  <Button
                    variant="secondary"
                    onClick={previousPage}
                    style={{width: '15vw'}}
                    disabled={startAt === 0}
                  >
                    {"Prev"}
                  </Button>
                </Col>
                <Col md={2} style={{ marginTop: 7 }}>
                  Page{" "}
                  <strong>
                    {pageIndex + 1}
                  </strong>
                </Col>
                <Col md={5}>
                  <Button variant="secondary" style={{width: '15vw', marginRight: '5px'}} onClick={nextPage} disabled={!moreResults}>
                    {"Next"}
                  </Button>
                </Col>
              </div>
            </div>
          }

        </div>
      </AuthorizationWrapper>
    );
  }

export default connect(mapStateToProps, mapDispatchToProps)(Audit);

