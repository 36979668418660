import React, {useEffect}      from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Row } from 'react-bootstrap';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import { actions as securityIncidentActions } from '../../ducks/securityIncidents/securityIncident.index';
import Loading            from '../../components/loading';
import ManageSecurityUsersTable from './ManageSecurityUsersTable';
import CreateSecurityUserModal from '../SecurityIncidents/Modals/CreateSecurityUserModal';
import { useAsyncDebounce } from 'react-table';
import { InputGroup, FormControl, Card } from 'react-bootstrap';
import {
  dateTimeToUTCString,
  sortOpi,
  sortDate,
} from '../../utils/formatFunctions'
import UpdateSecurityUserModal from '../SecurityIncidents/Modals/UpdateSecurityUserModal';
import DeleteSecurityUserModal from '../SecurityIncidents/Modals/DeleteSecurityUserModal';
const mapStateToProps = ({securityIncidentState}) => {
  return {
    securityUsersLoading  : securityIncidentState.securityUsersLoading,
    securityUsers         : securityIncidentState.securityUsers,
  }
};
const mapDispatchToProps = (dispatch) => {
const combinedActionCreators = {
  requestSecurityUsers     : securityIncidentActions.requestSecurityUsers,
};
return bindActionCreators(combinedActionCreators, dispatch);
};
const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
  },
  'pageTitle': {
    'width'        :  '73vw',
    'textAlign'    :  'center',
    'fontSize'     :  '2rem',
  },
};

const requiredAction = 'view_manage_security_users';
const ManageSecurityIncidentEmails = ({securityUsersLoading, requestSecurityUsers, securityUsers}) => {
  const { topContainer, pageTitle, headers } = styles;
  const [search, setSearch] = React.useState('');
  useEffect(() => {
    requestSecurityUsers('', '');
  }, []);
  const onChange = useAsyncDebounce(value => {
    setSearch(value || undefined)
    //this assumes any number typed into the filter is a company_id and any text is an email
    if (isNumeric(value)){
      requestSecurityUsers('', value);
    }
    else{
      requestSecurityUsers(value, '');
    }
  }, 800)
  const isNumeric = (str) => {
    if (typeof str != "string") return false 
    return !isNaN(str) && 
           !isNaN(parseFloat(str)) 
  }

  const columns = React.useMemo(
    () => [
        {
          Header: <b style={styles.test} data-cy='id-header'>ID</b>,
          headerStyle: headers,
          id: 'id',
          size: '122px',
          Cell          : ({value}) => <div>{value}</div>,
          accessor      : "id",
          sortMethod    : (a, b) => sortOpi(a, b),
          filter: 'includes'
        },
        {
          Header      : <b data-cy="internal-header">Company ID</b>,
          headerStyle : headers,
          id          : 'company_id',
          size       : '100px',
          accessor    : data => <div style={{width: '70px'}}>{data.company_id}</div>,
          filter: 'includes'
        },
        {
          Header: <b data-cy='service-header'>Email</b>,
          headerStyle: headers,
          id: 'email',
          accessor: (data) => data.email,
          size: '300px',
          filter: 'includes'
        },
        {
          Header: <b data-cy="start-date-header">Created Date</b> ,
          headerStyle: headers,
          id: 'created_date',
          accessor: (data) => dateTimeToUTCString(data.created_date),
          size: '230px',
          sortMethod    :  (a, b) => sortDate(a, b),
          filter: 'includes'
        },
        {
          Header: <b data-cy="start-date-header">Modified Date</b> ,
          headerStyle: headers,
          id: 'modified_date',
          accessor: (data) => data.modified_date != "None" ? dateTimeToUTCString(data.modified_date) : "",
          size: '230px',
          sortMethod    :  (a, b) => sortDate(a, b),
          filter: 'includes'
        },
        {
          Header: <b data-cy='data-center-header'>Edit</b>,
          headerStyle: headers,
          id: 'sec_comm_id2',
          width: 50,
          accessor: (data) => <UpdateSecurityUserModal data={data} />
        },
        {
          Header: <b data-cy='data-center-header'>Delete</b>,
          headerStyle: headers,
          id: 'sec_comm_id3',
          width: 50,
          accessor: (data) => <DeleteSecurityUserModal email={data.email} company_id={data.company_id} id={data.id} />
        },
      ]
    )

return (
  <AuthorizationWrapper requiredAction={requiredAction}>
    <div style={topContainer}>
      {
        securityUsersLoading
        ? <div>
            <div style={pageTitle}>Loading...</div>
            <Loading height="75vh" />
          </div>
        : <div>
            <Row>
              <div style={pageTitle}>Manage Security Incident Emails</div>
              <CreateSecurityUserModal  />
            </Row>
            <Card>
              <span>
                  <InputGroup>
                    <InputGroup.Prepend style={{margin: 'auto .5rem'}}>Filter{' '}</InputGroup.Prepend>
                    <FormControl id="search-bar" value={search || ""}
                      onChange={e => {
                        setSearch(e.target.value);
                        onChange(e.target.value);
                      }}
                      placeholder={`Search by Company ID or Email...`}
                      style={{
                        fontSize: '1.1rem',
                        borderTop: '0',
                        borderBottom: '0'
                      }} />
                  </InputGroup>
                </span>
            </Card>
            <ManageSecurityUsersTable data={securityUsers} columns={columns}/>
          </div>
      }
    </div>


  </AuthorizationWrapper>
)
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageSecurityIncidentEmails);