import { func, string, shape, bool, oneOfType, oneOf } from 'prop-types';
import { defaultFilters as defaultChangeHistoryFilters } from '../changeHistory/changeHistory.index';

/* ===== STATE_DEFINITION ===== */
export const defaultCustomerImpactSettings = {
  allFilters :  [],
  selected   :  {
    filters: [
      { 'column_name': 'customer_name', 'values': [] },
      { 'column_name': 'entity_code',   'values': [] },
      { 'column_name': 'outtask_id',    'values': [] },
    ]
  },
};

export const initialState = {
  user :  {
    email          :  null,
    appPreferences :  {},
    appRights      :  {},
    authorization  :  {"is_admin": false, "allowed_actions": []}
  },
  isLoggedIn :  false,
  fetching   :  null,
  isLoading  :  true,
  error      :  false,
  customerImpactSettings :  defaultCustomerImpactSettings,
  changeHistorySettings : defaultChangeHistoryFilters
};


/* ===== PROP_TYPES ===== */
export const userProptypes = {
  setUser     :  func,
  setFilters  :  func,
  logoutUser  :  func,
  isLoggedIn  :  bool,
  isLoading   :  bool,
  error       :  bool,
  fetching    :  oneOfType([ bool, oneOf([null]) ]),
  user        :  shape({
    email        :  string,
    // team         :  string,
    // timezone     :  string,
  }),
  // filters: arrayOf(shape(filterShape)),
}


/* ===== TYPES ===== */
export const types = {
  PROCESS_USER     :  '[user] PROCESS_USER',
  SET_USER         :  '[user] SET_USER',
  LOGOUT_USER      :  '[user] LOGOUT',
  SET_USER_LOADING :  '[user] SET_USER_LOADING',

  // customer impact tile
  SET_CUSTOMER_IMPACT_SETTINGS        :  '[user] SET_CUSTOMER_IMPACT_SETTINGS',
  PUT_CUSTOMER_IMPACT_SETTINGS        :  '[user] PUT_CUSTOMER_IMPACT_SETTINGS',
  ADD_CUSTOMER_IMPACT_FILTER          :  '[user] ADD_CUSTOMER_IMPACT_FILTER',
  UPDATE_CUSTOMER_IMPACT_FILTER       :  '[user] UPDATE_CUSTOMER_IMPACT_FILTER',
  SET_SELECTED_CUSTOMER_IMPACT_FILTER :  '[user] SET_SELECTED_CUSTOMER_IMPACT_FILTER',
  SET_SELECTED_FILTER_COLUMN          :  '[user] SET_SELECTED_FILTER_COLUMN',
  GENERATE_CUSTOMER_IMPACT_SETTING    :  '[user] GENERATE_CUSTOMER_IMPACT_SETTING',

  // change history tile
  SET_CHANGE_HISTORY_SETTINGS         :  '[user] SET_CHANGE_HISTORY_SETTINGS',
  PUT_CHANGE_HISTORY_SETTINGS         :  '[user] PUT_CHANGE_HISTORY_SETTINGS',
};


/* ===== ACTION_CREATORS ===== */
export const actions = {
  setUser         :  (userData)   => ({type: types.SET_USER,         payload: userData}),
  processUser     :  (getPayload) => ({type: types.PROCESS_USER,     payload: getPayload}),
  logoutUser      :  ()           => ({type: types.LOGOUT_USER}),
  setUserLoading  :  (bool)       => ({type: types.SET_USER_LOADING, payload: bool}),

  // customer impact tile
  setCustomerImpactSettings           :  (allFilters)  => ({type: types.SET_CUSTOMER_IMPACT_SETTINGS,        payload: allFilters }),
  putCustomerImpactSettings           :  (allFilters)  => ({type: types.PUT_CUSTOMER_IMPACT_SETTINGS,        payload: allFilters }),
  addCustomerImpactFilter             :  (filter)      => ({type: types.ADD_CUSTOMER_IMPACT_FILTER,          payload: filter }),
  updateCustomerImpactFilter          :  (filterId)    => ({type: types.UPDATE_CUSTOMER_IMPACT_FILTER,       payload: filterId }),
  setSelectedCustomerImpactFilter     :  (filter)      => ({type: types.SET_SELECTED_CUSTOMER_IMPACT_FILTER, payload: filter }),
  setSelectedFilterColumn             :  (col, values) => ({type: types.SET_SELECTED_FILTER_COLUMN,          payload: {col, values}}),
  generateAndAddCustomerImpactSetting :  (nickname)    => ({type: types.GENERATE_CUSTOMER_IMPACT_SETTING,    payload: nickname}),

  // change history tile
  setChangeHistorySettings            :  (allFilters)  => ({type: types.SET_CHANGE_HISTORY_SETTINGS,         payload: allFilters }),
  putChangeHistorySettings            :  (allFilters)  => ({type: types.PUT_CHANGE_HISTORY_SETTINGS,         payload: allFilters }),
};


/* ===== SELECTORS ===== */
export const selectors =  {
  getUserEmail: (state) => state.userState.user.email,
  selectCustomerImpactFilters: (filters) => {
    // converts arrays to strings (for the react-table package)
    return filters
    .map( (filter) => ({
      id    :  filter.column_name,
      value :  filter.values
    }))
    .filter( (filter) => filter.value !== '');  // remove empties else it messes with react-table package
  },
};


/* ===== REDUCER ===== */
export default function reducer(state=initialState, action) {
  switch (action.type) {

    // case types.REQUEST_USER: {
    //   return {
    //     ...state,
    //     fetching :  true,
    //   };
    // }

    case types.SET_USER: {
      return {
        ...state,
        user       :  action.payload,
        isLoggedIn :  true,
        fetching   :  null,
      };
    }

    case types.SET_CUSTOMER_IMPACT_SETTINGS: {
      return {
        ...state,
        customerImpactSettings : {
          ...state.customerImpactSettings,
          // This JSON parse must be done to create a "new" object, otherwise it will stay bound to the payload object
          allFilters :  JSON.parse(JSON.stringify(action.payload)),
        }
      };
    }

    case types.SET_SELECTED_CUSTOMER_IMPACT_FILTER: {
      return {
        ...state,
        customerImpactSettings : {
          ...state.customerImpactSettings,
          // This JSON parse must be done to create a "new" object, otherwise it will stay bound to the payload object
          selected :  JSON.parse(JSON.stringify(action.payload)),
        }
      };
    }

    case types.SET_SELECTED_FILTER_COLUMN: {
      const { col, values } = action.payload;

      // find index to update
      const colIndex = state.customerImpactSettings.selected.filters.findIndex(filter => filter.column_name === col);

      // update index's value
      const newState = {...state};
      newState.customerImpactSettings.selected.filters[colIndex].values = values;

      return newState;
    }

    case types.SET_CHANGE_HISTORY_SETTINGS: {
      return {
        ...state,
        changeHistorySettings : {
          // We don't want to bind changeHistorySettings to the payload object; this effectively copies the key/value pairs.
          ...action.payload
        }
      };
    }

    case types.SET_USER_LOADING: {
      return {
        ...state,
        isLoading :  action.payload,
      };
    }

    case types.LOGOUT_USER :  { return initialState; }

    default :  { return state; }
  }
}
